import Constants from "@/web/constants";
import FeedWallCommentModel from "@/web/store/models/FeedWallCommentModel";
import FeedWallThreadModel from "@/web/store/models/FeedWallThreadModel";
import axios from "@/web/services/axios";

const API_LIMIT = 10;

export const types = {
  LOAD_COMMENTS: "loadComments",
  LOAD_COMMENTS_SUCCESS: "loadCommentsSuccess",
  LOAD_COMMENTS_ERROR: "loadCommentsError",
  POST_COMMENT: "postComment",
  POST_COMMENT_SUCCESS: "postCommentSuccess",
  POST_COMMENT_ERROR: "postCommentError",
};

export const state = () => ({
  statuses: {},
  errors: {},
  metas: {},
  postCommentStatus: null,
  postCommentError: null,
});

export const mutations = {
  setMeta(state, { threadId, meta, lastCommentTimestamp }) {
    const newMeta = { ...state.metas };
    newMeta[threadId] = { allItems: meta.all_items, isMore: meta.is_more, lastCommentTimestamp };
    state.metas = newMeta;
  },

  resetMeta(state, threadId) {
    const newMeta = { ...state.metas };
    delete newMeta[threadId];
    state.metas = newMeta;
  },

  [types.LOAD_COMMENTS](state, threadId) {
    const statuses = { ...state.statuses };
    statuses[threadId] = Constants.STATUS_LOADING;
    state.statuses = statuses;
  },
  [types.LOAD_COMMENTS_SUCCESS](state, threadId) {
    const statuses = { ...state.statuses };
    statuses[threadId] = Constants.STATUS_LOADED;
    state.statuses = statuses;
  },
  [types.LOAD_COMMENTS_ERROR](state, { threadId, error }) {
    const statuses = { ...state.statuses };
    const errors = { ...state.errors };
    errors[threadId] = error;
    statuses[threadId] = Constants.STATUS_ERROR;
    state.statuses = statuses;
    state.errors = errors;
  },
  [types.POST_COMMENT](state) {
    state.postCommentStatus = Constants.STATUS_LOADING;
    state.postCommentError = null;
  },
  [types.POST_COMMENT_SUCCESS](state) {
    state.postCommentStatus = Constants.STATUS_LOADED;
  },
  [types.POST_COMMENT_ERROR](state, error) {
    state.postCommentError = error;
    state.postCommentStatus = Constants.STATUS_ERROR;
  },
};

export const actions = {
  async loadComments({ commit, dispatch, getters, rootState, rootGetters }, { channelId, threadId }) {
    const params = {
      limit: API_LIMIT,
      timestamp: getters.getLastCommentTimestamp(threadId),
    };

    if (!getters.getIsLoading(threadId) && (getters.getIsMore(threadId) || !getters.getLastCommentTimestamp(threadId))) {
      commit(types.LOAD_COMMENTS, threadId);
      const eventId = rootState.eventId;
      const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;

      return await FeedWallCommentModel.api()
        .get(`events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comments`, {
          params: params,
          persistBy: "insertOrUpdate",
          persistOptions: {
            insertOrUpdate: ["users", "attachments"],
          },
          dataTransformer: ({ data, headers }) => {
            const remoteComments = data.feed_wall_comments;
            if (remoteComments.length) {
              const lastCommentDate = remoteComments.slice(-1).pop().created_at;
              const lastCommentTimestamp = new Date(lastCommentDate).getTime();
              commit("setMeta", {
                threadId: threadId,
                meta: data.meta,
                lastCommentTimestamp: Number.parseInt(lastCommentTimestamp),
              });
            } else {
              commit("setMeta", { threadId: threadId, meta: data.meta, lastCommentTimestamp: null });
            }
            remoteComments.forEach(comment => {
              comment.feed_wall_thread_id = threadId;
            });
            return remoteComments;
          },
        })
        .then(result => {
          commit(types.LOAD_COMMENTS_SUCCESS, threadId);
        })
        .catch(err => {
          commit(types.LOAD_COMMENTS_ERROR, { threadId: threadId, error: err });
        });
    }
  },

  async updateComments({ rootState, rootGetters, getters }, { channelId, threadId }) {
    const eventId = rootState.eventId;
    const channel = rootGetters["feedWall/getChannelById"](channelId);

    if (channel) {
      const componentId = channel.event_component_id;
      const params = {
        limit: 100,
        timestamp: getters.getLatestCommentTimestamp(threadId),
      };
      return await FeedWallCommentModel.api()
        .get(`events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comments/update`, {
          params: params,
          persistBy: "insertOrUpdate",
          persistOptions: {
            insertOrUpdate: ["users", "attachments"],
          },
          dataTransformer: ({ data, headers }) => {
            const remoteComments = data.feed_wall_comments;
            remoteComments.forEach(comment => {
              comment.feed_wall_thread_id = threadId;
            });
            return remoteComments;
          },
        })
        .then(result => {
          const comments = result.response.data.feed_wall_comments;
          const lastCommentId = comments.length ? comments[0].id : null;
          const allItems = result.response.data.meta.all_items;
          FeedWallThreadModel.update({
            where: threadId,
            data: {
              feed_wall_comment_id: lastCommentId,
              comments_counter: allItems,
            },
          });
        });
    }
  },

  async postComment({ commit, state, getters, rootState, rootGetters }, { channelId, threadId, message }) {
    const eventId = rootState.eventId;
    const channel = rootGetters["feedWall/getChannelById"](channelId);
    if (channel) {
      const componentId = channel.event_component_id;
      commit(types.POST_COMMENT);

      const body = { message: message };
      return await FeedWallCommentModel.api()
        .post(`events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comment`, body, {
          dataTransformer: ({ data, headers }) => {
            const comment = data;
            comment.feed_wall_thread_id = threadId;
            return comment;
          },
        })
        .then(result => {
          const commentId = result.response.data.id;
          const thread = rootGetters["feedWallChannel/threadById"](threadId);
          FeedWallThreadModel.update({
            where: threadId,
            data: {
              feed_wall_comment_id: commentId,
              comments_counter: thread.comments_counter + 1,
            },
          });
          commit(types.POST_COMMENT_SUCCESS);
        })
        .catch(err => {
          commit(types.POST_COMMENT_ERROR, err);
        });
    } else {
      throw Constants.FEED_WALL_CHANNEL_NOT_FOUND;
    }
  },

  async editComment({ rootState, rootGetters }, { channelId, threadId, commentId, message }) {
    const eventId = rootState.eventId;
    const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;
    const body = {
      message: message,
    };
    return await FeedWallCommentModel.api().patch(
      `events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comment/${commentId}`,
      body,
      {
        dataTransformer: ({ data, headers }) => {
          const remoteComment = data;
          remoteComment.feed_wall_thread_id = threadId;
          return remoteComment;
        },
      }
    );
  },

  async deleteComment({ rootState, rootGetters, getters }, { channelId, threadId, commentId, isAdmin, isFromCurrentUser }) {
    const eventId = rootState.eventId;
    const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;

    let reqUrl = `events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/${threadId}/comment/${commentId}`;

    if (!isFromCurrentUser && isAdmin) {
      reqUrl = `${reqUrl}/admin`;
    }

    return await FeedWallCommentModel.api()
      .delete(reqUrl, {
        delete: commentId,
      })
      .then(result => {
        const thread = rootGetters["feedWallChannel/threadById"](threadId);
        let currentCommentId = thread.feed_wall_comment_id;
        if (currentCommentId === commentId) {
          let latestComment = getters.getLatestCommentFromThread(threadId);
          currentCommentId = (latestComment && latestComment.id) || null;
        }
        FeedWallThreadModel.update({
          where: threadId,
          data: {
            comments_counter: Math.max(0, thread.comments_counter - 1),
            feed_wall_comment_id: currentCommentId,
          },
        });
      });
  },

  async likeThread({ rootState, rootGetters }, { channelId, threadId }) {
    const eventId = rootState.eventId;
    const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;

    return await axios
      .post(`events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread/${threadId}/like`)
      .then(result => {
        const likesCounter = result.data.rating;
        FeedWallThreadModel.update({
          where: threadId,
          data: {
            likes_counter: likesCounter,
            liked_by_me: true,
          },
        });
      })
      .catch(err => {
        if (err.response.status === 422 && err.response.data.errors[0].code === Constants.FEED_WALL_POST_ALREADY_LIKED_ERROR_CODE) {
          FeedWallThreadModel.update({
            where: threadId,
            data: {
              liked_by_me: true,
            },
          });
        } else {
          throw err;
        }
      });
  },

  async unlikeThread({ rootState, rootGetters }, { channelId, threadId }) {
    const eventId = rootState.eventId;
    const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;

    return await axios
      .post(`events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread/${threadId}/unlike`)
      .then(result => {
        const likesCounter = result.data.rating;
        FeedWallThreadModel.update({
          where: threadId,
          data: {
            likes_counter: likesCounter,
            liked_by_me: false,
          },
        });
      })
      .catch(err => {
        if (err.response.status === 422 && err.response.data.errors[0].code === Constants.FEED_WALL_POST_NOT_LIKED_YET_ERROR_CODE) {
          FeedWallThreadModel.update({
            where: threadId,
            data: {
              liked_by_me: false,
            },
          });
        } else {
          throw err;
        }
      });
  },

  async deleteLocalComment({ state, rootGetters }, { threadId, commentId }) {
    return FeedWallCommentModel.delete(commentId).then(result => {
      const thread = rootGetters["feedWallChannel/threadById"](threadId);
      let currentCommentId = thread.feed_wall_comment_id;
      if (currentCommentId === commentId) {
        let latestComment = getters.getLatestCommentFromThread(threadId);
        currentCommentId = (latestComment && latestComment.id) || null;
      }
      FeedWallThreadModel.update({
        where: threadId,
        data: {
          comments_counter: Math.max(0, thread.comments_counter - 1),
          feed_wall_comment_id: currentCommentId,
        },
      });
    });
  },
};

export const getters = {
  commentsFromThread: state => threadId => {
    // eslint-disable-next-line
    return FeedWallCommentModel
      .query()
      .where("feed_wall_thread_id", threadId)
      .orderBy(comment => -new Date(comment.created_at))
      .withAllRecursive()
      .all();
  },

  getIsLoading: state => threadId => state.statuses[threadId] === Constants.STATUS_LOADING,

  getError: state => threadId => state.errors[threadId],

  getIsMore: state => threadId => {
    const threadMeta = state.metas[threadId];
    if (threadMeta) {
      return threadMeta.isMore;
    } else {
      return true;
    }
  },

  getLastCommentTimestamp: state => threadId => {
    const channelMeta = state.metas[threadId];
    if (channelMeta) {
      return channelMeta.lastCommentTimestamp;
    } else {
      return null;
    }
  },

  getLatestCommentTimestamp: state => threadId => {
    const latestComment = FeedWallCommentModel.query()
      .where("feed_wall_thread_id", threadId)
      .orderBy(comment => -new Date(comment.created_at))
      .withAllRecursive()
      .first();
    return (latestComment && new Date(latestComment.created_at).getTime()) || null;
  },

  getLatestCommentFromThread: state => threadId => {
    // eslint-disable-next-line
    return FeedWallCommentModel.query()
      .where("feed_wall_thread_id", threadId)
      .orderBy(comment => -new Date(comment.created_at))
      .withAllRecursive()
      .first();
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
