import { Constants } from "@/web/constants";
import axios from "@/web/services/axios";
import SpeakerModeratorModel from "@/web/store/models/SpeakerModeratorModel";
import ExhibitorModeratorModel from "@/web/store/models/ExhibitorModeratorModel";

export const state = () => ({
  accessesStatus: null,
  accessesError: null,
});

export const types = {
  LOAD_ACCESSES: "loadAccesses",
  LOAD_ACCESSES_SUCCESS: "loadAccessesSuccess",
  LOAD_ACCESSES_ERROR: "loadAccessesError",
};

export const mutations = {
  [types.LOAD_ACCESSES](state) {
    state.accessesStatus = Constants.STATUS_LOADING;
  },
  [types.LOAD_ACCESSES_SUCCESS](state) {
    state.accessesStatus = Constants.STATUS_LOADED;
  },
  [types.LOAD_ACCESSES_ERROR](state, error) {
    state.accessesStatus = Constants.STATUS_ERROR;
    state.accessesError = error;
  },
};
export const actions = {
  async fetchAccesses({ commit, rootState }) {
    commit(types.LOAD_ACCESSES);
    // const localExhibitorModeratorIds = ExhibitorModeratorModel.all().map(it => it.id);
    await axios
      .get(`events/${rootState.eventId}/moderator/accesses`)
      .then(result => {
        let exhibitorsModerator = result.data.exhibitors.map(mapExhibitorToExhibitorModerator);
        let speakers = result.data.speakers.map(mapSpeakerToSpeakerModerator);

        ExhibitorModeratorModel.deleteAll();
        ExhibitorModeratorModel.insertOrUpdate({
          data: exhibitorsModerator,
        });
        SpeakerModeratorModel.deleteAll();
        SpeakerModeratorModel.insertOrUpdate({
          data: speakers,
        });
        return result;
      })
      .catch(err => {
        commit(types.LOAD_ACCESSES_ERROR, err);
        throw err;
      });
    commit(types.LOAD_ACCESSES_SUCCESS);
  },
};
export const getters = {
  isLoaded(state) {
    return state.accessesStatus === Constants.STATUS_LOADED;
  },

  isLoading(state) {
    return state.accessesStatus === Constants.STATUS_LOADING;
  },

  exhibitorAccesses(state) {
    return ExhibitorModeratorModel.query()
      .withAllRecursive()
      .all()
      .map(model => model.exhibitor);
  },

  speakerAccesses(state) {
    return SpeakerModeratorModel.query()
      .withAllRecursive()
      .all()
      .map(model => model.speaker);
  },

  hasAccess(state, getters) {
    return !!(getters.speakerAccesses.length || getters.exhibitorAccesses.length);
  },
};

function mapExhibitorToExhibitorModerator(exhibitor) {
  return {
    id: exhibitor.id,
    exhibitor: exhibitor,
  };
}

function mapSpeakerToSpeakerModerator(speaker) {
  return {
    id: speaker.id,
    speaker: speaker,
  };
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
