<template>
  <div :class="{ 'modal-card': isModal }">
    <header
      :class="{
        'pt-0': !isModal,
        'modal-card-head': isModal,
        'mb-2': !isModal,
      }"
    >
      <h2 v-if="registerTitle && isModal" class="modal-card-title is-size-4">{{ registerTitle }}</h2>
      <p v-if="registerSubtitle" class="modal-card-subtitle has-text-centered is-size-6" v-html="registerSubtitle"></p>
      <div class="p-1" v-else></div>
      <button class="delete" type="button" @click="$parent.close" v-if="isModal"></button>
    </header>

    <section :class="{ 'modal-card-body': isModal }">
      <register-form :showProgress="isRequesting" @submit="userRegister"></register-form>
    </section>

    <footer :class="{ 'modal-card-foot': isModal, 'pt-3': !isModal }">
      <div class="has-text-centered mb-3">
        <i18n path="auth.already_have_an_account" tag="span" class="auth-footer-text">
          <a @click.prevent="openLogin">
            {{ $t("auth.already_have_an_account_substring") }}
          </a>
        </i18n>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Constants from "@/web/constants";
import RequestMixin from "@/shared/mixins/RequestMixin";
import RedirectMixin from "@/web/mixins/RedirectMixin";
import LpConfigMixin from "@/shared/mixins/LpConfigMixin";
import RegisterForm from "@/web/components/forms/RegisterForm";
import EmailTakenModal from "@/web/views/modals/EmailTakenModal";
import EmailConfirmationModal from "@/web/views/modals/EmailConfirmationModal";
import FormBuilderConstants from "@/web/views/dev/formbuilder/form-builder-constants";

export default {
  mixins: [RequestMixin, RedirectMixin, LpConfigMixin],

  name: "RegisterModal",

  props: {
    isModal: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    RegisterForm,
  },

  data() {
    return {
      lastEmail: "",
    };
  },

  requests: {
    async userRegister(credentials) {
      this.openAuthLoadingModal();
      this.lastEmail = credentials["email"];
      await this.register({ credentials });
      this.afterRegister();
    },

    async userRegisterFromCustomForm({ credentials, metaData, profileData }) {
      this.openAuthLoadingModal();
      this.lastEmail = credentials["email"];
      await this.registerWithAdditionalData({ credentials, metaData, profileData });
      this.afterRegister();
    },
  },

  watch: {
    error(newVal) {
      if (newVal) {
        this.setAuthLoadingStatus(Constants.AUTH_LOADING_STATUSES.ERROR);
        const data = newVal.response.data;
        if (data && data.errors && Array.isArray(data.errors)) {
          const error = data.errors[0];
          if (error && error.code === Constants.REST_EMAIL_TAKEN_ERROR) {
            this.$buefy.modal.open({
              parent: this,
              component: EmailTakenModal,
              hasModalCard: true,
              canCancel: true,
              trapFocus: true,
              props: {
                errorMessage: error.message,
                errorCode: error.code,
              },
            });
          } else if (error && error.code === Constants.REST_EMAIL_NEEDS_TO_BE_CONFIRMED) {
            // logEvent(getAnalytics(), Constants.ANALYTICS_EVENTS.USER_REGISTERED, {
            //   [Constants.ANALYTICS_PARAMETERS.AUTHORIZATION_TYPE]: Constants.ANALYTICS_AUTHORIZATION_TYPES.EMAIL,
            // });
            this.$buefy.modal.open({
              parent: this,
              component: EmailConfirmationModal,
              hasModalCard: true,
              canCancel: true,
              trapFocus: true,
              props: {
                email: this.lastEmail,
              },
            });
          } else if (error && error.code === Constants.REST_EMAIL_ACCOUNT_ALREADY_CONFIRMED) {
            this.$buefy.modal.open({
              parent: this,
              component: EmailTakenModal,
              hasModalCard: true,
              canCancel: true,
              trapFocus: true,
              props: {
                errorMessage: this.$t("email_confirmation.resend_error_account_already_confirmed"),
                errorCode: error.code,
              },
            });
          } else {
            this.$root.openErrorModal(newVal);
          }
        }
        this.error = null;
      }
    },

    emailTakenModalStatus: {
      handler: function (newValue) {
        if (newValue) {
          this.setEmailTakenModalStatus(false);
          this.openLogin();
        }
      },
    },
  },

  computed: {
    ...mapState(["settings"]),
    ...mapState("auth", ["emailTakenModalStatus"]),
    ...mapGetters(["customRegisterFormData"]),
    ...mapGetters("auth", ["isJoined"]),

    registerTitle() {
      const translationNamespace = this.getFieldValueOrDefault(
        this.customRegisterFormData,
        FormBuilderConstants.TRANSLATIONS_NAMESPACE,
        null
      );
      if (translationNamespace) {
        if (this.customRegisterFormData[FormBuilderConstants.TITLE] === false) {
          return false;
        } else {
          return this.getCustomTranslation(
            this.customRegisterFormData,
            FormBuilderConstants.TITLE,
            this.$t("auth.register_title"),
            translationNamespace
          );
        }
      } else {
        return this.$t("auth.register_title");
      }
    },

    registerSubtitle() {
      const translationNamespace = this.getFieldValueOrDefault(
        this.customRegisterFormData,
        FormBuilderConstants.TRANSLATIONS_NAMESPACE,
        null
      );
      if (translationNamespace) {
        if (this.customRegisterFormData[FormBuilderConstants.SUBTITLE] === false) {
          return false;
        } else {
          return this.getCustomTranslation(
            this.customRegisterFormData,
            FormBuilderConstants.SUBTITLE,
            this.$t("auth.register_subtitle"),
            translationNamespace
          );
        }
      } else {
        return this.$t("auth.register_subtitle");
      }
    },
  },

  methods: {
    ...mapActions(["register", "requestFacebookToken", "registerWithAdditionalData"]),
    ...mapMutations("auth", ["setEmailTakenModalStatus", "setAuthLoadingStatus"]),

    afterRegister() {
      if (this.isJoined) {
        this.close();
      } else {
        this.openLogin();
        this.openWhitelistModal();
      }
    },

    openLogin() {
      if (this.isModal) {
        this.$root.openLoginModal(this.$props);
        this.$parent.close();
      } else {
        this.$router.push({ name: Constants.ROUTE_LOGIN });
      }
    },

    openAuthLoadingModal() {
      this.$root.openAuthLoadingModal();
    },

    openWhitelistModal() {
      this.$root.openWhitelistModal();
    },

    close() {
      if (this.isModal) {
        this.nextRedirect();
        this.$parent.close();
      } else {
        const defaultRoute = { name: Constants.ROUTE_MAIN_INTRODUCTION };
        this.nextRedirect(defaultRoute);
      }
    },
  },
};
</script>
