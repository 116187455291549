import axios from "@/web/services/axios";
import { Constants } from "@/web/constants";

export const types = {
  LOAD_DATA: "loadQuizLeaderboard",
  LOAD_DATA_SUCCESS: "loadQuizLeaderboardSuccess",
  LOAD_DATA_ERROR: "loadQuizLeaderboardError",
};

export const state = {
  status: null,
  error: null,
  leaderboard: [],
  componentName: "",
};

export const getters = {
  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },
  hasLoadingError(state) {
    return state.status === Constants.STATUS_ERROR;
  },
};

export const mutations = {
  [types.LOAD_DATA](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_DATA_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_DATA_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
  setLeaderboard(state, quiz) {
    state.leaderboard = quiz.scores;
    state.componentName = quiz.name;
  },
};

export const actions = {
  async loadLeaderboard({ commit }, uuid) {
    const url = `/survey_leaderboard_external/${uuid}`;

    return axios.get(url).then(
      response => {
        const results = response.data;
        const sortedResults = results.scores.sort((a, b) => {
          if (a.points !== b.points) {
            return a.points < b.points ? 1 : -1;
          } else {
            return new Date(a.last_response) >= new Date(b.last_response) ? 1 : -1;
          }
        });
        results.scores = sortedResults;
        commit("setLeaderboard", results);
        commit(types.LOAD_DATA_SUCCESS);
        return Promise.resolve(sortedResults);
      },
      err => {
        commit(types.LOAD_DATA_ERROR, err);
        return Promise.reject(err);
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
