import { Constants } from "@/web/constants";
import SpeakerModel from "@/web/store/models/SpeakerModel";
import AgendaSessionModel from "@/web/store/models/AgendaSessionModel";

export const state = () => ({
  status: null,
  error: null,
});
export const types = {
  LOAD_SPEAKERS: "loadSpeakers",
  LOAD_SPEAKERS_SUCCESS: "loadSpeakersSuccess",
  LOAD_SPEAKERS_ERROR: "loadSpeakersError",
};
export const mutations = {
  [types.LOAD_SPEAKERS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_SPEAKERS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_SPEAKERS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};
export const actions = {
  async loadSpeakers({ dispatch }, { eventId, componentId }) {
    return await dispatch("fetch", { eventId, componentId });
  },
  async fetch({ commit }, { eventId, componentId }) {
    commit(types.LOAD_SPEAKERS);
    return SpeakerModel.api()
      .get(`events/${eventId}/components/${componentId}/speakers`)
      .then(speakers => {
        commit(types.LOAD_SPEAKERS_SUCCESS);
      })
      .catch(err => {
        commit(types.LOAD_SPEAKERS_ERROR, err);
        throw err;
      });
  },
  async fetchById({ commit }, { eventId, componentId, speakerId }) {
    return SpeakerModel.api().get(`events/${eventId}/components/${componentId}/speakers/${speakerId}`);
  },
  async fetchSpeakerAgendaSessions({ commit }, { eventId, componentId }) {
    return AgendaSessionModel.api().get(`events/${eventId}/components/${componentId}/agenda_sessions`, {
      persistOptions: {
        insertOrUpdate: ["speakers"],
      },
    });
  },
};
export const getters = {
  getById: state => speakerId => {
    return SpeakerModel.query().whereId(speakerId).withAllRecursive().first();
  },
  getAllFromComponent: state => componentId => {
    if (componentId) {
      return SpeakerModel.query().whereId(componentId).withAll().get();
    }
    return [];
  },
  searchFromComponent: state => (componentId, searchQuery) => {
    if (componentId) {
      let speakers;
      if (searchQuery.length === 0) {
        speakers = SpeakerModel.query().where("event_component_id", componentId).withAllRecursive().get();
      } else {
        speakers = SpeakerModel.query()
          .where("event_component_id", componentId)
          .search(searchQuery, {
            keys: ["first_name", "last_name", "position", "company"],
            caseSensitive: false,
            tokenize: true,
            threshold: 0.2,
          })
          .withAllRecursive()
          .get();
      }
      return speakers.sort((a, b) => {
        return a.order - b.order;
      });
    }
    return [];
  },
  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },
  isLoading(state) {
    return state.status === Constants.STATUS_LOADING;
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
