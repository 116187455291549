import { generateEventColors } from "@/shared/utils";

export function setEvent(state, event) {
  const lpSettings = JSON.parse(event.lp_settings);
  const eventColors = lpSettings.colors || generateEventColors(event);

  const appId = event.app_id;

  state.slug = event.lp_url;
  state.event = event;
  state.appId = appId;
  state.eventId = event.id;
  state.timezone = event.timezone;
  state.settings = lpSettings;
  state.eventColors = eventColors;

  this.commit("adminPanel/setConfig", JSON.parse(event.lp_settings));
}

export function setLpSettings(state, lpSettings) {
  state.settings = lpSettings;
}

export function updateCurrentTime(state) {
  state.currentTime = new Date();
}

export function setAskUserToSubmitMetadata(state, value) {
  state.askUserToSubmitMetadata = value;
}
