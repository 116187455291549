import Constants from "@/web/constants";
import BusinessMatchingFilterGroup from "@/web/store/models/BusinessMatchingFilterGroup";

export const types = {
  LOAD_BM_FILTERS: "loadBMFilters",
  LOAD_BM_FILTERS_SUCCESS: "loadBMFiltersSuccess",
  LOAD_BM_FILTERS_ERROR: "loadBMFiltersError",
};

export const state = () => ({
  status: null,
  error: null,
});

export const mutations = {
  [types.LOAD_BM_FILTERS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_BM_FILTERS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_BM_FILTERS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};

export const actions = {
  async fetchFiltersFromEvent({commit, dispatch, getters}, eventId) {
    if (!getters.isLoading) {
      commit(types.LOAD_BM_FILTERS);
      return BusinessMatchingFilterGroup.api()
        .get(`events/${eventId}/business_matching/filters/user`)
        .then(result => {
          commit(types.LOAD_BM_FILTERS_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_BM_FILTERS_ERROR, err);
        });
    } else {
      return Promise.resolve();
    }
  },
};

export const getters = {
  isLoading: state => state.status === Constants.STATUS_LOADING,

  getFiltersFromEvent: state => eventId => {
    return BusinessMatchingFilterGroup.query()
      .where("event_id", eventId)
      .where("question_visible", true)
      .withAllRecursive()
      .all()
      .sort((a, b) => a.order - b.order);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
