import AgendaSessionModel from "@/web/store/models/AgendaSessionModel";
import Constants from "@/web/constants";
import AgendaSessionQuestionModel from "@/web/store/models/AgendaSessionQuestionModel";
import axios from "@/web/services/axios";

export const types = {
  LOAD_QUESTIONS: "loadQuestions",
  LOAD_QUESTIONS_SUCCESS: "loadQuestionsSuccess",
  LOAD_QUESTIONS_ERROR: "loadQuestionsError",
};

const agendaSessionsListTypes = {
  ONGOING: 0,
  UPCOMING: 1,
  FINISHED: 2,
};

const questionsFilterTypes = {
  DATE: 0,
  LIKES: 1,
  MODERATOR_RECENT: 2,
  MODERATOR_OLDEST: 3,
};

export const state = () => ({
  visibleAgendaSessionId: null,
  selectedAgendaSessionList: agendaSessionsListTypes.ONGOING,
  selectedQuestionsFilterType: questionsFilterTypes.DATE,
  statuses: {},
  errors: {},
  currentTime: null,
});

export const mutations = {
  updateCurrentTime(state) {
    state.currentTime = new Date();
  },
  setVisibleAgendaSessionId(state, agendaSessionId) {
    state.visibleAgendaSessionId = agendaSessionId;
  },
  selectAgendaSessionsList(state, listId) {
    state.selectedAgendaSessionList = listId;
  },
  selectQuestionsFilterType(state, type) {
    state.selectedQuestionsFilterType = type;
  },
  [types.LOAD_QUESTIONS](state, agendaSessionId) {
    const statuses = { ...state.statuses };
    statuses[agendaSessionId] = Constants.STATUS_LOADING;
    state.statuses = statuses;
  },
  [types.LOAD_QUESTIONS_SUCCESS](state, agendaSessionId) {
    const statuses = { ...state.statuses };
    statuses[agendaSessionId] = Constants.STATUS_LOADED;
    state.statuses = statuses;
  },
  [types.LOAD_QUESTIONS_ERROR](state, { agendaSessionId, error }) {
    const statuses = { ...state.statuses };
    const errors = { ...state.errors };
    errors[agendaSessionId] = error;
    statuses[agendaSessionId] = Constants.STATUS_ERROR;
    state.statuses = statuses;
    state.error = errors;
  },
};

export const actions = {
  async fetchQuestions({ commit, getters, rootState, state, rootGetters }, { componentId, agendaSessionId, isUpdateForAdmin }) {
    if (!getters.getIsLoading(agendaSessionId) && state.visibleAgendaSessionId === agendaSessionId) {
      let isCurrentUserAdmin = rootGetters["auth/isAdmin"];
      if (!isUpdateForAdmin || (isUpdateForAdmin && isCurrentUserAdmin)) {
        const eventId = rootState.eventId;
        commit(types.LOAD_QUESTIONS, agendaSessionId);

        const localQuestionIds = AgendaSessionQuestionModel.query()
          .where("agenda_session_id", agendaSessionId)
          .get()
          .map(it => it.id);
        let url = `events/${eventId}/components/${componentId}/agenda_session/${agendaSessionId}/discussion/questions`;
        if (isCurrentUserAdmin) {
          url += "/admin";
        }
        return await AgendaSessionQuestionModel.api()
          .get(url)
          .then(result => {
            const remoteQuestionIds = result.response.data.map(question => question.id);
            let idsToDelete = localQuestionIds.filter(questionId => !remoteQuestionIds.includes(questionId));
            if (idsToDelete.length) {
              AgendaSessionQuestionModel.delete(question => idsToDelete.includes(question.id));
            }
            commit(types.LOAD_QUESTIONS_SUCCESS, agendaSessionId);
          })
          .catch(error => {
            commit(types.LOAD_QUESTIONS_ERROR, { agendaSessionId, error });
          });
      }
    } else {
      return Promise.resolve();
    }
  },

  async postQuestion({ rootState }, { componentId, agendaSessionId, message, anonymous }) {
    const eventId = rootState.eventId;

    const body = {
      question: message,
      anonymous: anonymous,
    };

    return AgendaSessionQuestionModel.api().post(
      `events/${eventId}/components/${componentId}/agenda_session/${agendaSessionId}/discussion/question`,
      body
    );
  },

  async deleteQuestion({ rootState, rootGetters, getters }, { componentId, agendaSessionId, questionId, isAdmin, isFromCurrentUser }) {
    const eventId = rootState.eventId;

    let reqUrl = `events/${eventId}/components/${componentId}/agenda_session/${agendaSessionId}/discussion/question/${questionId}/delete`;

    if (!isFromCurrentUser && isAdmin) {
      reqUrl = `${reqUrl}/admin`;
    }

    return await AgendaSessionQuestionModel.api().delete(reqUrl, {
      delete: questionId,
    });
  },

  async approveQuestion({ rootState, rootGetter, getters }, { componentId, agendaSessionId, questionId }) {
    const eventId = rootState.eventId;

    return await AgendaSessionQuestionModel.api()
      .patch(`events/${eventId}/components/${componentId}/agenda_session/${agendaSessionId}/discussion/question/${questionId}/accept`)
      .then(result => {
        AgendaSessionQuestionModel.update({
          where: questionId,
          data: {
            accepted_by_moderator: true,
          },
        });
      });
  },

  async likeQuestion({ rootState, rootGetters }, { componentId, agendaSessionId, questionId }) {
    const eventId = rootState.eventId;

    return await axios
      .post(`events/${eventId}/components/${componentId}/agenda_session/${agendaSessionId}/discussion/${questionId}/rating/like`)
      .then(result => {
        const likesCounter = result.data.rating;
        AgendaSessionQuestionModel.update({
          where: questionId,
          data: {
            likes: likesCounter,
            liked_by_me: true,
          },
        });
      })
      .catch(err => {
        if (err.response.status === 422 && err.response.data.errors[0].code === Constants.AGENDA_QUESTION_ALREADY_LIKED_ERROR_CODE) {
          AgendaSessionQuestionModel.update({
            where: questionId,
            data: {
              liked_by_me: true,
            },
          });
        } else {
          throw err;
        }
      });
  },

  async unlikeThread({ rootState, rootGetters }, { componentId, agendaSessionId, questionId }) {
    const eventId = rootState.eventId;

    return await axios
      .post(`events/${eventId}/components/${componentId}/agenda_session/${agendaSessionId}/discussion/${questionId}/rating/unlike`)
      .then(result => {
        const likesCounter = result.data.rating;
        AgendaSessionQuestionModel.update({
          where: questionId,
          data: {
            likes: likesCounter,
            liked_by_me: false,
          },
        });
      })
      .catch(err => {
        if (err.response.status === 422 && err.response.data.errors[0].code === Constants.AGENDA_QUESTION_NOT_LIKED_YET_ERROR_CODE) {
          AgendaSessionQuestionModel.update({
            where: questionId,
            data: {
              liked_by_me: false,
            },
          });
        } else {
          throw err;
        }
      });
  },
};

export const getters = {
  getQuestionsBySessionId: state => agendaSessionId => {
    return AgendaSessionQuestionModel.query()
      .where("agenda_session_id", agendaSessionId)
      .withAllRecursive()
      .all()
      .filter(question => {
        if (
          state.selectedQuestionsFilterType === questionsFilterTypes.MODERATOR_RECENT ||
          state.selectedQuestionsFilterType === questionsFilterTypes.MODERATOR_OLDEST
        ) {
          return !question.accepted_by_moderator;
        } else {
          return true;
        }
      })
      .sort((a, b) => {
        if (state.selectedQuestionsFilterType === questionsFilterTypes.DATE) {
          return Date.parse(b.created_at) - Date.parse(a.created_at);
        } else if (state.selectedQuestionsFilterType === questionsFilterTypes.LIKES) {
          if (b.likes === a.likes) {
            return Date.parse(b.created_at) - Date.parse(a.created_at);
          } else {
            return b.likes - a.likes;
          }
        } else if (state.selectedQuestionsFilterType === questionsFilterTypes.MODERATOR_RECENT) {
          if (a.accepted_by_moderator === b.accepted_by_moderator) {
            return Date.parse(b.created_at) - Date.parse(a.created_at);
          } else {
            return a.accepted_by_moderator - b.accepted_by_moderator;
          }
        } else if (state.selectedQuestionsFilterType === questionsFilterTypes.MODERATOR_OLDEST) {
          if (a.accepted_by_moderator === b.accepted_by_moderator) {
            return Date.parse(a.created_at) - Date.parse(b.created_at);
          } else {
            return a.accepted_by_moderator - b.accepted_by_moderator;
          }
        } else {
          throw Error("unknown QuestionsFilterType");
        }
      });
  },

  getSessionById: state => agendaSessionId => {
    return AgendaSessionModel.query().whereId(agendaSessionId).withAllRecursive().first();
  },

  allAgendaSessionLists() {
    return Object.values(agendaSessionsListTypes);
  },

  allQuestionsFilterTypes(state, getters, rootState, rootGetters) {
    if (rootGetters["auth/isAdmin"]) {
      return Object.values(questionsFilterTypes);
    } else {
      return Object.values(questionsFilterTypes).slice(0, 2);
    }
  },

  visibleAgendaSessions(state, getters) {
    if (state.selectedAgendaSessionList === agendaSessionsListTypes.ONGOING) {
      return getters.getOngoingSessions;
    } else if (state.selectedAgendaSessionList === agendaSessionsListTypes.UPCOMING) {
      return getters.getUpcomingSessions;
    } else if (state.selectedAgendaSessionList === agendaSessionsListTypes.FINISHED) {
      return getters.getFinishedSessions;
    } else {
      throw Error("unknown agenda sessions list");
    }
  },

  getFirstSession(state) {
    const sessions = AgendaSessionModel.query()
      .all()
      .sort((a, b) => Date.parse(a.time_start) - Date.parse(b.time_start));
    if (sessions.length) {
      return sessions[0];
    } else {
      return null;
    }
  },

  getOngoingSessions(state) {
    if (state.currentTime) {
      return AgendaSessionModel.query()
        .where(session => {
          return new Date(session.time_start) < state.currentTime && new Date(session.time_end) > state.currentTime;
        })
        .where("agenda_discussion_on", true)
        .withAllRecursive()
        .all()
        .sort((a, b) => Date.parse(a.time_start) - Date.parse(b.time_start));
    } else {
      return [];
    }
  },

  getUpcomingSessions(state) {
    if (state.currentTime) {
      return AgendaSessionModel.query()
        .where(session => {
          return state.currentTime < new Date(session.time_start);
        })
        .where("agenda_discussion_on", true)
        .withAllRecursive()
        .all()
        .sort((a, b) => Date.parse(a.time_start) - Date.parse(b.time_start))
        .slice(0, 10);
    } else {
      return [];
    }
  },

  getFinishedSessions(state) {
    const copiedDate = new Date(state.currentTime);
    copiedDate.setHours(copiedDate.getHours() - 2);

    if (state.currentTime) {
      return AgendaSessionModel.query()
        .where(session => {
          return new Date(session.time_end) < state.currentTime;
        })
        .where("agenda_discussion_on", true)
        .withAllRecursive()
        .all()
        .sort((a, b) => Date.parse(b.time_end) - Date.parse(a.time_end))
        .slice(0, 10);
    } else {
      return [];
    }
  },

  isSessionDiscussionEnabled: state => AgendaSessionModel.query().where("agenda_discussion_on", true).count() > 0,

  getIsLoading: state => agendaSessionId => state.statuses[agendaSessionId] === Constants.STATUS_LOADING,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
