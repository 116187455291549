import {Model} from "@vuex-orm/core";

export default class UserUnavailabilityModel extends Model {
  static entity = "user_unavailability";

  static fields() {
    return {
      id: this.attr(null),
      unavailability_from: this.string(""),
      unavailability_to: this.string(""),
    };
  }
}
