<template>
  <form action="." method="POST" @submit.prevent="submit">
    <!-- Email address field -->
    <div class="field mb-3">
      <label class="label has-text-primary">{{ $t("auth.email") }}</label>
      <div class="control">
        <input class="input is-simple" type="text" :placeholder="$t('auth.email')" v-model="email"/>
      </div>
      <template v-if="submitted">
        <p v-if="!$v.email.required" class="help is-danger">{{ $t("auth.error_email") }}</p>
        <p v-if="!$v.email.email" class="help is-danger">{{ $t("auth.error_email") }}</p>
      </template>

      <div class="has-text-secondary is-size-7 mt-1">
        {{ $t("auth.password_reminder_description") }}
      </div>
    </div>

    <!-- Submit button -->
    <div class="field">
      <div class="control">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="showProgress">
          {{ $t("auth.password_reminder_send") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "ForgotPasswordForm",

  props: ["showProgress"],

  data() {
    return {
      submitted: false,
      email: ""
    };
  },

  validations: {
    email: {
      required,
      email: value => email(value.toLowerCase().replace(/\s/g, "")),
    }
  },

  methods: {
    submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      if (!this.$v.$invalid) {
        const { email } = this.$data;
        this.$emit("submit", { email: email.toLowerCase().replace(/\s/g, "") });
      }
    }
  }
};
</script>
