import { Model } from "@vuex-orm/core";
import SpeakerModel from "@/web/store/models/SpeakerModel";
import AgendaSessionSpeaker from "@/web/store/models/AgendaSessionSpeaker";
import AgendaSessionPathModel from "@/web/store/models/AgendaSessionPathModel";
import EventDayModel from "@/web/store/models/EventDayModel";

export default class AgendaSessionModel extends Model {
  static entity = "agenda_sessions";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.attr(null),
      event_id: this.attr(null),
      name: this.string(""),
      description: this.string(""),
      order: this.number(0),
      time_start: this.string(""),
      time_end: this.string(""),
      registration: this.string(""),
      registration_limit: this.string(""),
      agenda_discussion_on: this.boolean(false),
      video_call_url: this.string("").nullable(),
      qr_code: this.string(""),
      speakers: this.belongsToMany(SpeakerModel, AgendaSessionSpeaker, "agenda_session_id", "speaker_id"),
      tag_agenda_place_id: this.attr(null),
      tag_agenda_place: this.belongsTo(AgendaSessionPathModel, "tag_agenda_place_id"),
      tag_agenda_path_id: this.attr(null),
      tag_agenda_path: this.belongsTo(AgendaSessionPathModel, "tag_agenda_path_id"),
      agenda_session_day_id: this.attr(null),
      agenda_session_day: this.belongsTo(EventDayModel, "agenda_session_day_id")
    };
  }
}
