import { Model } from "@vuex-orm/core";

export default class TicketModel extends Model {
  static entity = "tickets";

  static fields() {
    return {
      id: this.number(null),
      price: this.number(),
      currency: this.string(),
      name: this.string().nullable(),
      description: this.string(),
    };
  }
}
