import { Model } from "@vuex-orm/core";
import SpeakerModel from "@/web/store/models/SpeakerModel";

export default class SpeakerModeratorModel extends Model {
  static entity = "speaker_moderators";

  static fields() {
    return {
      id: this.attr(null),
      speaker_id: this.attr(null),
      speaker: this.belongsTo(SpeakerModel, "speaker_id"),
    };
  }
}
