<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ title }}
      </h1>
      <div class="modal-close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle has-text-centered" v-html="desc">
      </div>
      <div>
        <button class="button is-fullwidth is-success" style="margin-top: 1rem" @click="$parent.close()">
          {{ $t("common.ok") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";

export default {
  name: "SuccessModal",

  props: {
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      default: null,
    },
  },

  components: {
    CloseIcon,
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}
</style>
