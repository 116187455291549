<template>
  <form action="." method="POST" @submit.prevent="submit">
    <!-- First name field -->
    <div class="columns is-mobile is-vcentered mb-0" ref="firstNameAndLastNameFields">
      <div class="column is-half">
        <div class="field">
          <label class="label" :class="textColorClass">{{ $t("auth.auth_firstname_placeholder") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('auth.auth_firstname_placeholder')"
                   v-model="firstName"/>
          </div>
          <template v-if="submitted">
            <p v-if="!$v.firstName.required" class="help is-danger">{{ $t("auth.error_first_name") }}</p>
          </template>
        </div>
      </div>
      <div class="column is-half">
        <!-- Last name field -->
        <div class="field">
          <label class="label" :class="textColorClass">{{ $t("auth.auth_lastname_placeholder") }}</label>
          <div class="control">
            <input class="input is-simple" type="text" :placeholder="$t('auth.auth_lastname_placeholder')"
                   v-model="lastName"/>
          </div>
          <template v-if="submitted">
            <p v-if="!$v.lastName.required" class="help is-danger">{{ $t("auth.error_last_name") }}</p>
          </template>
        </div>
      </div>
    </div>

    <!-- Email address field -->
    <div class="field" ref="emailField">
      <label class="label" :class="textColorClass">{{ $t("auth.email") }}</label>
      <div class="control">
        <input class="input is-simple" type="text" :placeholder="$t('auth.email')" v-model="email"/>
      </div>
      <template v-if="submitted">
        <p v-if="!$v.email.required" class="help is-danger">{{ $t("auth.error_email") }}</p>
        <p v-if="!$v.email.email" class="help is-danger">{{ $t("auth.error_email") }}</p>
        <p v-if="eventId === 1318 && !$v.email.dolby" class="help is-danger">{{ $t("custom.dolby_register_error") }}</p>
      </template>
    </div>

    <div class="columns is-mobile mb-1" ref="passwordFields">
      <div class="column is-half">
        <!-- Password field -->
        <div class="field">
          <label class="label" :class="textColorClass">{{ $t("auth.password") }}</label>
          <div class="control is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
            <input class="input is-simple" :type="passwordInputType" :placeholder="$t('auth.password')"
                   v-model="password"/>
            <div class="password-visibility-button" @click="togglePasswordVisibility">
              <visibility-off-icon class="svg-icon has-fill-primary" v-if="isPasswordVisible"></visibility-off-icon>
              <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
            </div>
          </div>
          <template v-if="submitted">
            <p v-if="!$v.password.required" class="help is-danger">{{ $t("auth.error_password") }}</p>
            <p v-if="!$v.password.minLength" class="help is-danger">{{ $t("common.field_too_short") }}</p>
          </template>
        </div>
      </div>

      <div class="column is-half">
        <!-- Repeat Password field -->
        <div class="field">
          <label class="label" :class="textColorClass">{{ $t("auth.repeat_password") }}</label>
          <div class="control is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
            <input
              class="input is-simple"
              :type="repeatPasswordInputType"
              :placeholder="$t('auth.repeat_password')"
              v-model="repeatPassword"
            />
            <div class="password-visibility-button" @click="toggleRepeatPasswordVisibility">
              <visibility-off-icon class="svg-icon has-fill-primary"
                                   v-if="isRepeatPasswordVisible"></visibility-off-icon>
              <visibility-icon class="svg-icon has-fill-primary" v-else></visibility-icon>
            </div>
          </div>
          <template v-if="submitted">
            <p v-if="!$v.repeatPassword.sameAsPassword" class="help is-danger">{{
                $t("auth.error_repeat_password")
              }}</p>
          </template>
        </div>
      </div>
    </div>

    <!-- Privacy policy and terms of service consent -->
    <div class="field" ref="privacyPolicyField">
      <div class="control">
        <label class="checkbox">
          <checkbox class="checkbox-input" v-model="terms"></checkbox>
          <i18n path="auth.terms_and_conditions" tag="span" class="consents" :class="termsClass"
                @click="terms = !terms">
            <a target="_blank" @click.stop="0"
               :href="$t('url.privacy')">{{ $t("auth.privacy_policy_substring") }}</a>
            <a target="_blank" @click.stop="0" :href="$t('url.terms')">{{
                $t("auth.terms_of_service_substring")
              }}</a>
          </i18n>
        </label>
      </div>
    </div>

    <!-- GDPR consent -->
    <div class="field mb-3" ref="gdprField">
      <div class="control">
        <label class="checkbox consents">
          <checkbox class="checkbox-input" v-model="gdpr"></checkbox>
          <i18n path="auth.gdpr_terms" tag="span" class="consents" :class="gdprClass" @click="gdpr = !gdpr">
            <a target="_blank" @click.stop="0" :href="$t('url.gdpr')">
              {{ $t("auth.read_more") }}
            </a>
          </i18n>
        </label>
      </div>
    </div>

    <div class="field has-text-danger has-text-centered" v-if="$v.$anyError">
      {{ $t("auth.error_required_fields") }}
    </div>

    <!-- Submit button -->
    <div class="field">
      <div class="control">
        <button class="button is-primary is-fullwidth" type="submit" :disabled="showProgress">
          {{ $t("auth.dont_have_an_account_substring") }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import {required, minLength, email, sameAs} from "vuelidate/lib/validators";
import {mapGetters, mapState} from "vuex";
import Checkbox from "@/shared/components/form/Checkbox";
import VisibilityIcon from "@/assets/auth/icon_visibility.svg";
import VisibilityOffIcon from "@/assets/auth/icon_visibility_off.svg";

export default {
  name: "RegisterForm",

  props: ["showProgress"],

  components: {Checkbox, VisibilityIcon, VisibilityOffIcon},

  data() {
    return {
      submitted: false,
      email: "",
      password: "",
      repeatPassword: "",
      isPasswordVisible: false,
      isRepeatPasswordVisible: false,
      firstName: "",
      lastName: "",
      terms: false,
      gdpr: false,
    };
  },

  validations() {
    let validations = {
      email: {
        required,
        email: value => email(value.toLowerCase().replace(/\s/g, "")),
      },
      password: {required, minLength: minLength(7)},
      firstName: {required},
      lastName: {required},
      repeatPassword: {
        sameAsPassword: sameAs("password"),
      },
      terms: {accepted},
      gdpr: {accepted},
    };
    if(this.eventId === 1318) {
      validations.email.dolby = (value) => {
        return value.toLowerCase().includes("@dolby.com") || value.toLowerCase().includes("@compani.pl");
      };
    }
    return validations;
  },

  methods: {
    submit() {
      this.$v.$touch();
      this.$data.submitted = true;

      if (!this.$v.$invalid) {
        const {email, password, firstName, lastName} = this.$data;
        this.$emit("submit", {email: email.toLowerCase().replace(/\s/g, ""), password, firstName, lastName});
      } else {
        this.scrollToFirstFieldWithError();
      }
    },

    scrollToFirstFieldWithError() {
      const scrollArgs = {behavior: "smooth", block: "start"};

      if (this.$v.firstName.$anyError || this.$v.lastName.$anyError) {
        this.$refs.firstNameAndLastNameFields.scrollIntoView(scrollArgs);
      } else if (this.$v.email.$anyError) {
        this.$refs.emailField.scrollIntoView(scrollArgs);
      } else if (this.$v.password.$anyError || this.$v.repeatPassword.$anyError) {
        this.$refs.passwordFields.scrollIntoView(scrollArgs);
      } else if (this.$v.terms.$anyError) {
        this.$refs.privacyPolicyField.scrollIntoView(scrollArgs);
      } else if (this.$v.gdpr.$anyError) {
        this.$refs.gdprField.scrollIntoView(scrollArgs);
      }
    },

    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },

    toggleRepeatPasswordVisibility() {
      this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
    },
  },

  computed: {
    ...mapGetters("locales", ["currentLocale"]),
    ...mapState(["eventId"]),

    passwordInputType() {
      if (this.isPasswordVisible) {
        return "text";
      } else {
        return "password";
      }
    },

    repeatPasswordInputType() {
      if (this.isRepeatPasswordVisible) {
        return "text";
      } else {
        return "password";
      }
    },

    textColorClass() {
      return "has-text-primary";
    },

    gdprClass() {
      if (this.submitted && !this.$v.gdpr.accepted) {
        return "has-text-danger";
      } else {
        return "";
      }
    },

    termsClass() {
      if (this.submitted && !this.$v.terms.accepted) {
        return "has-text-danger";
      } else {
        return "";
      }
    },
  },
};

function accepted(value) {
  return !!value;
}
</script>

<style lang="scss" scoped>
.consents {
  font-size: 13px;
}

.gdpr-label {
  margin-left: 27px;
}

.intbeta-extra-info {
  white-space: pre-line;
  font-size: 13px;
}
</style>
