import axios from "@/web/services/axios";
import { Constants } from "@/web/constants";
import UserModel from "@/web/store/models/UserModel";

const API_LIMIT = 40;
const API_LIMIT_SEARCH = 100;

export const types = {
  LOAD_ATTENDEES: "loadAttendees",
  LOAD_ATTENDEES_SUCCESS: "loadAttendeesSuccess",
  LOAD_ATTENDEES_ERROR: "loadAttendeesError",
};

export const state = () => ({
  status: null,
  error: null,
  lastAttendeeId: null,
  allItems: null,
  isMore: true,
  searchResult: null,
  agendaSessionId: null,
  agendaSessionTitle: "",
});

export const mutations = {
  setAgendaSessionAttendeesSource(state, agendaSessionId) {
    state.componentId = null;
    state.agendaSessionId = agendaSessionId;
  },

  setEventAttendeesSource(state) {
    state.componentId = null;
    state.agendaSessionId = null;
  },

  setComponentAttendeesSource(state, componentId) {
    state.agendaSessionId = null;
    state.componentId = componentId;
  },

  setMeta(state, {meta, lastAttendeeId}) {
    state.allItems = meta.all_items;
    state.isMore = meta.is_more;
    state.lastAttendeeId = lastAttendeeId;
  },

  resetMeta(state) {
    state.lastAttendeeId = null;
    state.allItems = null;
    state.isMore = true;
  },

  setSearchResult(state, foundAttendees) {
    state.searchResult = foundAttendees;
  },

  clearSearchResult(state) {
    state.searchResult = null;
  },

  setAgendaSessionTitle(state, title) {
    state.agendaSessionTitle = title;
  },

  [types.LOAD_ATTENDEES](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_ATTENDEES_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_ATTENDEES_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};

export const actions = {
  async loadAttendeesPage({state, dispatch}) {
    if (state.agendaSessionId) {
      dispatch("loadAgendaSessionAttendeesPage");
    } else if (state.componentId) {
      dispatch("loadAgendaSessionAttendeesPage");
    } else {
      dispatch("loadEventAttendeesPage");
    }
  },

  async loadEventAttendeesPage({commit, state, rootState}) {
    const eventId = rootState.eventId;
    if (state.status !== Constants.STATUS_LOADING && state.isMore && !state.searchResult) {
      commit(types.LOAD_ATTENDEES);
      const params = {
        limit: API_LIMIT,
        max_id: state.lastAttendeeId,
      };
      return await UserModel.api()
        .get(`events/${eventId}/users`, {
          params: params,
          dataTransformer: ({ data, headers }) => {
            const lastAttendeeId = data.users.slice(-1).pop().user_identity_token;
            commit("setMeta", { meta: data.meta, lastAttendeeId: lastAttendeeId });

            const users = data.users;
            for (const user of users) {
              user.is_attendee = true;
            }
            return users;
          },
        })
        .then(result => {
          commit(types.LOAD_ATTENDEES_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_ATTENDEES_ERROR, err);
        });
    } else {
      return Promise.resolve();
    }
  },

  async loadAgendaSessionAttendeesPage({commit, state, rootState}) {
    const eventId = rootState.eventId;
    const agendaSessionId = state.agendaSessionId;

    if (state.status !== Constants.STATUS_LOADING && state.isMore && !state.searchResult) {
      commit(types.LOAD_ATTENDEES);
      const params = {
        limit: API_LIMIT,
        max_id: state.lastAttendeeId,
      };

      return await UserModel.api()
        .get(`events/${eventId}/attendance/agenda_sessions/${agendaSessionId}`, {
          params: params,
          dataTransformer: ({data, headers}) => {
            const lastAttendeeId = data.users.slice(-1).pop().user_identity_token;
            commit("setMeta", {meta: data.meta, lastAttendeeId: lastAttendeeId});

            const users = data.users;
            for (const user of users) {
              user.is_attendee = agendaSessionId;
            }
            return users;
          },
        })
        .then(result => {
          commit(types.LOAD_ATTENDEES_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_ATTENDEES_ERROR, err);
        });
    } else {
      return Promise.resolve();
    }
  },

  async loadComponentAttendeesPage({commit, state, rootState}) {
    const eventId = rootState.eventId;
    const componentId = state.componentId;

    if (state.status !== Constants.STATUS_LOADING && state.isMore && !state.searchResult) {
      commit(types.LOAD_ATTENDEES);
      const params = {
        limit: API_LIMIT,
        max_id: state.lastAttendeeId,
      };

      return await UserModel.api()
        .get(`events/${eventId}/components/${componentId}/users_with_access`, {
          params: params,
          dataTransformer: ({data, headers}) => {
            const lastAttendeeId = data.users.slice(-1).pop().user_identity_token;
            commit("setMeta", {meta: data.meta, lastAttendeeId: lastAttendeeId});

            const users = data.users;
            for (const user of users) {
              user.is_attendee = componentId;
            }
            return users;
          },
        })
        .then(result => {
          commit(types.LOAD_ATTENDEES_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_ATTENDEES_ERROR, err);
        });
    } else {
      return Promise.resolve();
    }
  },

  async searchAttendees({commit, state, rootState}, searchQuery) {
    commit(types.LOAD_ATTENDEES);
    commit("setSearchResult", []);
    const eventId = rootState.eventId;
    const params = {
      limit: API_LIMIT_SEARCH,
      search: searchQuery,
    };
    let url;
    if (state.agendaSessionId) {
      url = `events/${eventId}/attendance/agenda_sessions/${state.agendaSessionId}/search`;
    } else if (state.componentId) {
      url = `events/${eventId}/components/${state.componentId}/users_with_access/search`;
    } else {
      url = `events/${eventId}/users/search`;
    }
    return await axios
      .get(url, {params: params})
      .then(result => {
        const foundAttendees = result.data.users;
        commit("setSearchResult", foundAttendees);
        commit(types.LOAD_ATTENDEES_SUCCESS);
      })
      .catch(err => {
        commit(types.LOAD_ATTENDEES_ERROR, err);
      });
  },

  async loadAgendaSession({rootState, commit}, {agendaComponentId, agendaSessionId}) {
    const eventId = rootState.eventId;
    await axios.get(`events/${eventId}/components/${agendaComponentId}/agenda_session/${agendaSessionId}`).then(result => {
      commit("setAgendaSessionTitle", result.data.name);
    });
  },

  async loadUserProfile({commit}, userId) {
    return await UserModel.api()
      .get(`user/${userId}`)
      .then(result => result.response.data);
  },

  async clearAttendees({commit}) {
    await UserModel.deleteAll();
    commit("clearSearchResult");
    commit("resetMeta");
  },
};

export const getters = {
  getAttendees: (state, getters, rootState, rootGetters) => (searchQuery, filterOutCurrentUser) => {
    const currentUserUuid = rootGetters["currentUser/uuid"];
    if (searchQuery.length) {
      return state.searchResult.filter(user => {
        return !filterOutCurrentUser || currentUserUuid !== user.user_identity_token;
      });
    } else if (state.agendaSessionId) {
      // eslint-disable-next-line
      return UserModel.query()
        .withAllRecursive()
        .where(user => {
          return user.is_attendee === state.agendaSessionId && (!filterOutCurrentUser || currentUserUuid !== user.user_identity_token);
        })
        .orderBy(user => user.last_name.toLowerCase())
        .orderBy(user => user.first_name.toLowerCase())
        .all();
    } else if (state.componentId) {
      // eslint-disable-next-line
      return UserModel.query()
        .withAllRecursive()
        .where(user => {
          return user.is_attendee === state.componentId && (!filterOutCurrentUser || currentUserUuid !== user.user_identity_token);
        })
        .orderBy(user => user.last_name.toLowerCase())
        .orderBy(user => user.first_name.toLowerCase())
        .all();
    } else {
      // eslint-disable-next-line
      return UserModel.query()
        .withAllRecursive()
        .where(user => {
          return user.is_attendee && (!filterOutCurrentUser || currentUserUuid !== user.user_identity_token);
        })
        .orderBy(user => user.last_name.toLowerCase())
        .orderBy(user => user.first_name.toLowerCase())
        .all();
    }
  },

  getUserById: state => userId => {
    return UserModel.query().withAllRecursive().where("user_identity_token", userId).first();
  },

  isLoading: state => state.status === Constants.STATUS_LOADING,
};

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
  getters,
};
