
export const types = {
  SET_RESPONSES: "setResponses",
  START_NEW_QUIZ: "startNewQuiz",
  CLEAR_QUIZ: "clearQuiz",
  NEXT_QUESTION: "nextQuestion",
  PREVIOUS_QUESTION: "previousQuestion",
  SET_FIRST_UNANSWERED_QUESTION: "setFirstUnansweredQuestion",
};

const initialState = {
  currentQuiz: null,
  currentQuestion: null,
  answers: {},
};

export const state = () => initialState;

export const mutations = {
  [types.SET_RESPONSES](state, answer) {
    if (answer === null) {
      delete state[state.currentQuestion.id];
    } else {
      state.answers = {...state.answers, [state.currentQuestion.id]: answer};
    }
  },
  [types.START_NEW_QUIZ](state, quiz) {
    quiz.survey_quiz_questions = quiz.survey_quiz_questions.sort((a, b) => a.order - b.order);
    const currentQuestion = quiz.survey_quiz_questions[0];
    currentQuestion.survey_quiz_answers = currentQuestion.survey_quiz_answers.sort((a, b) => a.order - b.order);

    state.answers = {};
    state.currentQuiz = quiz;
    state.currentQuestion = currentQuestion;
  },
  [types.CLEAR_QUIZ](state) {
    state.answers = {};
    state.currentQuiz = null;
    state.currentQuestion = null;
  },
  [types.NEXT_QUESTION](state) {
    const currentIndex = state.currentQuiz.survey_quiz_questions.indexOf(state.currentQuestion);
    if (currentIndex !== -1 && currentIndex < state.currentQuiz.survey_quiz_questions.length - 1) {
      state.currentQuestion = state.currentQuiz.survey_quiz_questions[currentIndex + 1];
    }
  },
  [types.PREVIOUS_QUESTION](state) {
    const currentIndex = state.currentQuiz.survey_quiz_questions.indexOf(state.currentQuestion);
    if (currentIndex !== -1 && currentIndex > 0) {
      state.currentQuestion = state.currentQuiz.survey_quiz_questions[currentIndex - 1];
    }
  },
  [types.SET_FIRST_UNANSWERED_QUESTION](state) {
    const unansweredQuestion = state.currentQuiz.survey_quiz_questions.find(question => !state.answers[question.id]);
    if (unansweredQuestion) {
      state.currentQuestion = unansweredQuestion;
    }
  }
};

export const actions = {
  startNewQuiz({commit}, quiz) {
    commit(types.START_NEW_QUIZ, quiz);
  },

  clearCurrentQuiz({commit}) {
    commit(types.CLEAR_QUIZ);
  },

  setResponses({commit}, responses) {
    commit(types.SET_RESPONSES, responses);
  },

  nextQuestion({commit, state}) {
    commit(types.NEXT_QUESTION);
  },

  previousQuestion({commit, state}) {
    commit(types.PREVIOUS_QUESTION);
  },

  checkIfAllQuestionsAreAnswered({commit, state}) {
    const allQuestionIds = state.currentQuiz.survey_quiz_questions.map(question => question.id);
    const responseQuestionIds = Object.keys(state.answers).map(key => Number.parseInt(key));

    const containsAll = (arr1, arr2) => arr2.every(arr2Item => arr1.includes(arr2Item));

    const sameMembers = (arr1, arr2) => containsAll(arr1, arr2) && containsAll(arr2, arr1);

    return sameMembers(allQuestionIds, responseQuestionIds);
  },

  navigateToUnansweredQuestion({commit, state}) {
    commit(types.SET_FIRST_UNANSWERED_QUESTION);
  }
};

export const getters = {
  allResponses: state => {
    return Object.values(state.answers).reduce((acc, value) => {
      return [...acc, ...value];
    }, []);
  },

  currentQuizId: state => {
    return state.currentQuiz && state.currentQuiz.id;
  },

  currentResponses: state => {
    return state.answers[state.currentQuestion.id];
  },

  currentQuestion: state => {
    return state.currentQuestion;
  },

  questionCount: state => {
    return state.currentQuiz && state.currentQuiz.survey_quiz_questions.length;
  },

  currentQuestionIndex: state => {
    return state.currentQuiz && state.currentQuiz.survey_quiz_questions.indexOf(state.currentQuestion);
  },

  isLastQuestion: state => {
    return (
      state.currentQuiz &&
      state.currentQuiz.survey_quiz_questions.indexOf(state.currentQuestion) === state.currentQuiz.survey_quiz_questions.length - 1
    );
  },

  isFirstQuestion: state => {
    return state.currentQuiz && state.currentQuiz.survey_quiz_questions.indexOf(state.currentQuestion) === 0;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
