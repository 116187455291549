import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import VueI18n from "vue-i18n";
import Vuelidate from "vuelidate";
import Buefy from "buefy";
import dayjs from 'dayjs';

import VueChatScroll from "vue-chat-scroll";
import Notifications from "vue-notification";
import VueFullscreenPlugin from "vue-fullscreen";

import Theme from "@/theme";
import OpenGraphTags from "@/web/plugins/open-graph-tags";
import CustomTranslations from "@/shared/plugins/custom-translations";
import PreventParentScroll from "@/web/directives/prevent-parent-scroll";
import ClickOutside from "@/shared/directives/click-outside";
import VueGapi from "vue-gapi";
import ICalParser from "@/web/plugins/ical-parser";

import { createStore } from "./store";
import { createRouter } from "./router";
import { createLocalization } from "./locales";

import {pluckError} from "../shared/utils";
import linkifyHtml from "linkifyjs/html";

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(Vuelidate);
Vue.use(Buefy);
Vue.use(Theme, true);
Vue.use(OpenGraphTags);
Vue.use(CustomTranslations);
Vue.use(ICalParser);
Vue.use(VueChatScroll);
Vue.use(Notifications);
Vue.use(VueFullscreenPlugin);

Vue.use(PreventParentScroll);
Vue.use(ClickOutside);

Vue.use(VueGapi, {
  clientId: "220904532384-srmhm1o86m0m4ihnf7hqiutkt1np6vdq.apps.googleusercontent.com",
  scope: "https://www.googleapis.com/auth/calendar.readonly",
});

// Globally accessible filters
Vue.filter("pluckError", value => pluckError(value));

Vue.filter("linkify", value =>
  linkifyHtml(value, {
    defaultProtocol: "https",
    target: {
      url: "_blank",
    },
  })
);

// Hide Vue info on startup
Vue.config.productionTip = false;

// Change locale
Vue.prototype.$setLocale = function (locale) {
  import(`dayjs/locale/${locale}`).then(() => {
    dayjs.locale(locale);
  });
  this.$i18n.locale = locale;
};

export function createApplication(options) {
  const config = Object.assign(configure(), options);
  return new Vue(config);
}

export function configure() {
  return {
    store: createStore(),
    router: createRouter(),
    i18n: createLocalization(),
  };
}
