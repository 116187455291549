import Constants from "@/web/constants";
import FeedWallThreadModel from "@/web/store/models/FeedWallThreadModel";
import axios from "@/web/services/axios";

const API_LIMIT = 10;

export const types = {
  LOAD_THREADS: "loadThreads",
  LOAD_THREADS_SUCCESS: "loadThreadsSuccess",
  LOAD_THREADS_ERROR: "loadThreadsError",
};

export const state = () => ({
  statuses: {},
  errors: {},
  metas: {},
});

export const mutations = {
  setMeta(state, { channelId, meta, lastThreadTimestamp }) {
    const newMeta = { ...state.metas };
    newMeta[channelId] = { allItems: meta.all_items, isMore: meta.is_more, lastThreadTimestamp };
    state.metas = newMeta;
  },

  resetMeta(state, channelId) {
    const newMeta = { ...state.metas };
    delete newMeta[channelId];
    state.metas = newMeta;
  },

  [types.LOAD_THREADS](state, channelId) {
    const statuses = { ...state.statuses };
    statuses[channelId] = Constants.STATUS_LOADING;
    state.statuses = statuses;
  },
  [types.LOAD_THREADS_SUCCESS](state, channelId) {
    const statuses = { ...state.statuses };
    statuses[channelId] = Constants.STATUS_LOADED;
    state.statuses = statuses;
  },
  [types.LOAD_THREADS_ERROR](state, { channelId, error }) {
    const statuses = { ...state.statuses };
    const errors = { ...state.errors };
    errors[channelId] = error;
    statuses[channelId] = Constants.STATUS_ERROR;
    state.statuses = statuses;
    state.errors = errors;
  },
};

export const actions = {
  async loadThreads({ commit, dispatch, getters, rootState, rootGetters }, { channelId }) {
    const params = {
      limit: API_LIMIT,
      timestamp: getters.getLastThreadTimestamp(channelId),
    };
    if (!getters.getIsLoading(channelId) && (getters.getIsMore(channelId) || !getters.getLastThreadTimestamp(channelId))) {
      const eventId = rootState.eventId;
      const channel = rootGetters["feedWall/getChannelById"](channelId);
      if (channel) {
        const componentId = channel.event_component_id;
        commit(types.LOAD_THREADS, channelId);
        return await FeedWallThreadModel.api()
          .get(`events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads`, {
            params: params,
            dataTransformer: ({ data, headers }) => {
              const remoteThreads = data.feed_wall_threads;
              if (remoteThreads.length) {
                const lastThreadDate = remoteThreads.slice(-1).pop().created_at;
                const lastThreadTimestamp = new Date(lastThreadDate).getTime();
                commit("setMeta", {
                  channelId: channelId,
                  meta: data.meta,
                  lastThreadTimestamp: Number.parseInt(lastThreadTimestamp),
                });
              } else {
                commit("setMeta", { channelId: channelId, meta: data.meta, lastThreadTimestamp: null });
              }
              remoteThreads.forEach(thread => {
                thread.channel_id = channelId;
                if (thread.feed_wall_comment && thread.feed_wall_comment.length) {
                  thread.feed_wall_comment = thread.feed_wall_comment[0];
                } else {
                  delete thread.feed_wall_comment;
                }
              });
              return remoteThreads;
            },
          })
          .then(result => {
            commit(types.LOAD_THREADS_SUCCESS, channelId);
          })
          .catch(err => {
            commit(types.LOAD_THREADS_ERROR, { channelId: channelId, error: err });
          });
      } else {
        throw Constants.FEED_WALL_CHANNEL_NOT_FOUND;
      }
    }
  },

  async updateThreads({ rootState, rootGetters, getters }, { channelId }) {
    const eventId = rootState.eventId;
    const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;

    const params = {
      limit: 100,
      timestamp: getters.getLatestThreadTimestamp(channelId),
    };

    return await FeedWallThreadModel.api().get(
      `events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/threads/update`,
      {
        params: params,
        dataTransformer: ({ data, headers }) => {
          const remoteThreads = data.feed_wall_threads;
          remoteThreads.forEach(thread => {
            thread.channel_id = channelId;
            if (thread.feed_wall_comment && thread.feed_wall_comment.length) {
              thread.feed_wall_comment = thread.feed_wall_comment[0];
            } else {
              delete thread.feed_wall_comment;
            }
          });
          return remoteThreads;
        },
      }
    );
  },

  async loadThread({ commit, state, getters, rootState, rootGetters }, { channelId, threadId }) {
    const eventId = rootState.eventId;
    const channel = rootGetters["feedWall/getChannelById"](channelId);

    if (channel) {
      const componentId = channel.event_component_id;
      return await FeedWallThreadModel.api()
        .get(`events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread/${threadId}`, {
          dataTransformer: ({ data, headers }) => {
            const remoteThread = data;
            remoteThread.channel_id = channelId;
            if (remoteThread.feed_wall_comment && remoteThread.feed_wall_comment.length) {
              remoteThread.feed_wall_comment = remoteThread.feed_wall_comment[0];
            } else {
              delete remoteThread.feed_wall_comment;
            }
            return remoteThread;
          },
        })
        .catch(error => {
          if (error.response.status === 404) {
            throw Constants.FEED_WALL_THREAD_NOT_FOUND;
          } else {
            throw error;
          }
        });
    } else {
      throw Constants.FEED_WALL_CHANNEL_NOT_FOUND;
    }
  },

  async uploadThreadPicture({ state }, file) {
    const formData = new FormData();
    formData.append("file", file);
    return await axios
      .post("attachments", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(result => {
        return result.data;
      });
  },

  async postThread({ rootState, rootGetters }, { channelId, message, attachmentId }) {
    const eventId = rootState.eventId;
    const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;

    const body = {
      image: attachmentId,
      message: message,
    };

    return await FeedWallThreadModel.api().post(
      `events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread`,
      body,
      {
        dataTransformer: ({ data, headers }) => {
          const remoteThread = data;
          remoteThread.channel_id = channelId;
          return remoteThread;
        },
      }
    );
  },

  async editThread({ rootState, rootGetters }, { channelId, threadId, message, attachmentId }) {
    const eventId = rootState.eventId;
    const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;
    const body = {
      message: message,
      image: attachmentId,
    };
    return await FeedWallThreadModel.api().patch(
      `events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread/${threadId}`,
      body,
      {
        dataTransformer: ({ data, headers }) => {
          const remoteThread = data;
          remoteThread.channel_id = channelId;
          return remoteThread;
        },
      }
    );
  },

  async deleteThread({ rootState, rootGetters }, { channelId, threadId, isAdmin, isFromCurrentUser }) {
    const eventId = rootState.eventId;
    const componentId = rootGetters["feedWall/getChannelById"](channelId).event_component_id;

    let reqUrl = `events/${eventId}/components/${componentId}/feed_wall/channels/${channelId}/thread/${threadId}`;
    if (!isFromCurrentUser && isAdmin) {
      reqUrl = `${reqUrl}/admin`;
    }

    return await FeedWallThreadModel.api().delete(
      reqUrl,
      {
        delete: threadId,
      }
    );
  },

  async deleteLocalThread({ state }, { threadId }) {
    return FeedWallThreadModel.delete(threadId);
  },
};

export const getters = {
  threadsFromChannel: state => channelId => {
    // eslint-disable-next-line
    return FeedWallThreadModel
      .query()
      .where("channel_id", channelId)
      .orderBy(thread => !thread.is_sticky)
      .orderBy(thread => -new Date(thread.created_at))
      .withAllRecursive()
      .all();
  },

  threadById: state => threadId => {
    // eslint-disable-next-line
    return FeedWallThreadModel
      .query()
      .whereId(threadId)
      .withAllRecursive()
      .first();
  },

  getIsLoading: state => channelId => state.statuses[channelId] === Constants.STATUS_LOADING,

  getError: state => channelId => state.errors[channelId],

  getIsMore: state => channelId => {
    const channelMeta = state.metas[channelId];
    if (channelMeta) {
      return channelMeta.isMore;
    } else {
      return true;
    }
  },

  getLastThreadTimestamp: state => channelId => {
    const channelMeta = state.metas[channelId];
    if (channelMeta) {
      return channelMeta.lastThreadTimestamp;
    } else {
      return null;
    }
  },

  getLatestThreadTimestamp: state => channelId => {
    const latestThread = FeedWallThreadModel.query()
      .where("channel_id", channelId)
      .orderBy(thread => -new Date(thread.created_at))
      .withAllRecursive()
      .first();
    return (latestThread && new Date(latestThread.created_at).getTime()) || null;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
