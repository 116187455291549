import {Model} from "@vuex-orm/core";
import UserModel from "@/web/store/models/UserModel";
import AttachmentModel from "@/web/store/models/AttachmentModel";
import FeedWallCommentModel from "@/web/store/models/FeedWallCommentModel";

export default class FeedWallThreadModel extends Model {
  static entity = "feed_wall_threads";

  static fields() {
    return {
      id: this.attr(null),
      channel_id: this.attr(null),
      message: this.string(""),
      is_highlighted: this.boolean(false),
      is_sticky: this.boolean(false),
      liked_by_me: this.boolean(false),
      likes_counter: this.number(0),
      comments_counter: this.number(0),
      uuid: this.string(""),
      created_at: this.string(""),
      updated_at: this.string(""),
      user_id: this.attr(null),
      user: this.belongsTo(UserModel, "user_id"),
      image_id: this.attr(null),
      image: this.belongsTo(AttachmentModel, "image_id"),
      feed_wall_comment_id: this.attr(null),
      feed_wall_comment: this.belongsTo(FeedWallCommentModel, "feed_wall_comment_id"),
    };
  }
}
