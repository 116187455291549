import InboxThreadModel from "@/web/store/models/InboxThreadModel";
import InboxMessageModel from "@/web/store/models/InboxMessageModel";
import axios from "@/web/services/axios";
import {Constants} from "@/web/constants";
import {getRandomTempId} from "@/shared/utils";

//Pagination in inbox threads endpoint is buggy, so all threads has to be loaded at once
const API_LIMIT = 1000;

export const types = {
  LOAD_THREADS: "loadThreads",
  LOAD_THREADS_SUCCESS: "loadThreadsSuccess",
  LOAD_THREADS_ERROR: "loadThreadsError",
  CREATE_THREAD: "createThread",
  CREATE_THREAD_SUCCESS: "createThreadSuccess",
  CREATE_THREAD_ERROR: "createThreadError",
};

export const state = () => ({
  status: null,
  error: null,
  createThreadStatus: null,
  createThreadError: null,
  lastMessageId: null,
  allItems: null,
  isMore: true,
});

export const mutations = {
  setMeta(state, { meta, lastMessageId }) {
    state.allItems = meta.all_items;
    state.isMore = meta.is_more;
    state.lastMessageId = lastMessageId;
  },

  resetMeta(state) {
    state.lastMessageId = null;
    state.allItems = null;
    state.isMore = true;
  },

  [types.LOAD_THREADS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_THREADS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_THREADS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },

  [types.CREATE_THREAD](state) {
    state.createThreadStatus = Constants.STATUS_LOADING;
  },
  [types.CREATE_THREAD_SUCCESS](state) {
    state.createThreadStatus = Constants.STATUS_LOADED;
  },
  [types.CREATE_THREAD_ERROR](state, error) {
    state.createThreadStatus = Constants.STATUS_ERROR;
    state.createThreadError = error;
  },
};

export const actions = {
  async loadInboxThreads({ commit, state }) {
    if (state.status !== Constants.STATUS_LOADING && state.isMore) {
      commit(types.LOAD_THREADS);
      const params = {
        limit: API_LIMIT,
        timestamp: state.lastMessageId,
      };
      return await InboxThreadModel.api()
        .get("inbox/my_threads", {
          params: params,
          dataTransformer: ({ data, headers }) => {
            const lastMessageId = new Date(data.inbox_threads.slice(0, 1).pop().updated_at).getTime();
            commit("setMeta", { meta: data.meta, lastMessageId: lastMessageId });
            return data.inbox_threads;
          },
        })
        .then(result => {
          commit(types.LOAD_THREADS_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_THREADS_ERROR, err);
        });
    } else {
      return Promise.resolve();
    }
  },

  async refreshInboxThreads({ dispatch }) {
    const params = {
      limit: 10,
      max_id: null,
    };
    return await InboxThreadModel.api().get("inbox/my_threads/update", {
      params: params,
      dataTransformer: ({ data, headers }) => {
        return data.inbox_threads;
      },
    });
  },

  async loadInboxThread({ commit }, { threadId }) {
    return await InboxThreadModel.api().get(`inbox/threads/${threadId}`);
  },

  async checkIfThreadExists({commit}, {userId}) {
    return await axios.get(`inbox/check_threads`, {
      params: {
        "users[]": userId,
      },
    });
  },

  async createThread({commit, getters}, {message, userIds}) {
    if (!getters["isCreatingThread"]) {
      commit(types.CREATE_THREAD);
      const data = {
        message: message,
        users: userIds,
      };
      return await InboxThreadModel.api()
        .post("inbox/create", data)
        .then(result => {
          commit(types.CREATE_THREAD_SUCCESS);
          return result;
        })
        .catch(err => commit(types.CREATE_THREAD_ERROR, err));
    } else {
      return Promise.resolve();
    }
  },

  async responseToThread({commit}, {message, threadId}) {
    const data = {
      message: message,
      temp_id: getRandomTempId(36),
    };
    return await InboxMessageModel.api()
      .post(`inbox/threads/${threadId}/response`, data, {
        params: data,
      })
      .then(result => {
        const threadId = result.response.data.inbox_thread_id;
        const messageId = result.response.data.id;
        const currentDate = new Date().toISOString();
        InboxThreadModel.update({
          where: threadId,
          data: {
            message_id: messageId,
            updated_at: currentDate,
            read_at: currentDate,
          },
        });
      });
  },

  async markThreadAsRead({ commit }, { threadId }) {
    const thread = InboxThreadModel.query().where("id", threadId).first();
    if (!thread.read_at || (thread && new Date(thread.read_at) < new Date(thread.updated_at))) {
      return await axios.post(`inbox/threads/${threadId}/mark_as_read`).then(() => {
        if (thread) {
          const currentDate = new Date().toISOString();
          InboxThreadModel.update({
            where: thread.id,
            data: {
              read_at: currentDate,
            },
          });
        }
      });
    }
  },
};

export const getters = {
  getThreads: state => (searchQuery, currentUserUuid) => {
    if (searchQuery.length) {
      return InboxThreadModel.query()
        .withAllRecursive()
        .with("users", query => {
          query.search(searchQuery, {
            keys: ["first_name", "last_name"],
            caseSensitive: false,
            tokenize: true,
          });
        })
        .orderBy(thread => {
          return new Date(thread.updated_at).getTime();
        }, "desc")
        .all()
        .filter(thread => thread.users.find(user => user.user_identity_token !== currentUserUuid));
    } else {
      // eslint-disable-next-line
      return InboxThreadModel.query()
        .withAllRecursive()
        .orderBy(thread => -new Date(thread.updated_at))
        .all();
    }
  },

  threadById: state => threadId => {
    // eslint-disable-next-line
    return InboxThreadModel
      .query()
      .where("id", threadId)
      .withAllRecursive()
      .first();
  },

  isLoading: state => state.status === Constants.STATUS_LOADING,

  isCreatingThread: state => state.createThreadStatus === Constants.STATUS_LOADING,
};

export default {
  namespaced: true,
  state: state,
  mutations,
  actions,
  getters,
};
