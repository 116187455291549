import { Model } from "@vuex-orm/core";
import AttachmentModel from "./AttachmentModel";

export default class ExhibitorContactUserModel extends Model {
  static entity = "exhibitor_contact_users";
  static primaryKey = "user_identity_token";

  static fields() {
    return {
      user_identity_token: this.string(),
      user_external_uuid: this.string("").nullable(),
      first_name: this.string(""),
      last_name: this.string(""),
      is_friend: this.boolean(false),
      show_contact_details: this.boolean(false),
      email: this.string(""),
      qrcode: this.string(""),
      position: this.string("").nullable(),
      company: this.string("").nullable(),
      country: this.string("").nullable(),
      biography: this.string("").nullable(),
      phone_number: this.string("").nullable(),
      what_i_offer: this.string("").nullable(),
      what_i_need: this.string("").nullable(),
      facebook_profile: this.string("").nullable(),
      twitter_profile: this.string("").nullable(),
      linked_profile: this.string("").nullable(),
      youtube_profile: this.string("").nullable(),
      instagram_profile: this.string("").nullable(),
      picture_id: this.attr(null),
      picture: this.belongsTo(AttachmentModel, "picture_id"),
      user_tags: this.string("")
    };
  }
}
