<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <div v-if="eventLogo" class="box is-inline-flex has-logo is-clipped">
        <figure class="image is-centered is-80x80">
          <img class="logo-image" :src="eventLogo"/>
        </figure>
      </div>
      <div class="close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pb-1">
      <h1 class="modal-card-title has-text-centered mb-3">
        {{ title }}
      </h1>
      <div class="size-6 subtitle" v-html="message"></div>
    </section>
    <footer class="modal-card-foot">
      <div>
        <button class="button is-fullwidth is-primary" style="margin-top: 1rem" @click="$parent.close()">
          {{ $t("common.ok") }}
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";

export default {
  name: "NotificationDetailsModal",

  props: ["notification"],

  components: {CloseIcon},

  computed: {
    title() {
      return this.notification.title;
    },

    message() {
      return this.$options.filters.linkify(this.notification.message);
    },

    eventLogo() {
      return this.notification.event && this.notification.event.logo && this.notification.event.logo.file_thumbnail_200_url;
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;

  .close-icon {
    fill: #333333;
    width: 100%;
    height: 100%;
  }
}

.subtitle {
  white-space: pre-line;
}

.modal-card-title {
  white-space: pre-line;
}
</style>
