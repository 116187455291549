import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import VuexORMSearch from "@vuex-orm/plugin-search";

import axios from "@/web/services/axios";

import database from "@/web/store/database";

import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

import auth from "./modules/auth/auth";
import locales from "./modules/locales";
import facebook from "./modules/auth/facebook";
import google from "./modules/auth/google";
import apple from "./modules/auth/apple";
import accessCode from "./modules/access-code";
import currentUser from "./modules/current-user";
import userTickets from "./modules/user-tickets";
import inbox from "./modules/inbox/inbox";
import inboxUsers from "./modules/inbox/inbox-users";
import inboxThreads from "./modules/inbox/inbox-threads";
import inboxMessages from "./modules/inbox/inbox-messages";
import notifications from "./modules/notifications";
import friends from "./modules/friends";
import attendees from "./modules/attendees";
import partners from "./modules/partners";
import exhibitors from "./modules/exhibitors";
import speakers from "./modules/speakers";
import quizzes from "./modules/quizzes";
import quizAnswers from "./modules/quiz-answers";
import feedWall from "./modules/feedwall/feed-wall";
import feedWallChannel from "./modules/feedwall/feed-wall-channel";
import feedWallThread from "./modules/feedwall/feed-wall-thread";
import components from "./modules/components";
import eventTickets from "./modules/event-tickets";
import payment from "./modules/payment";
import questions from "./modules/questions";
import quizLeaderboard from "./modules/quiz-leaderboard";
import surveyResults from "./modules/survey-results";
import treasureLeaderboard from "./modules/treasure-leaderboard";
import pusher from "./modules/pusher";
import sideMenu from "./modules/side-menu";
import adminPanel from "./modules/admin-panel";
import agendaSessionQuestions from "./modules/agenda-session-questions";
import businessMatching from "./modules/businessmatching/business-matching";
import businessMatchingAvailability from "./modules/businessmatching/business-matching-availability";
import businessMatchingSuggestions from "./modules/businessmatching/business-matching-suggestions";
import businessMatchingMeetings from "./modules/businessmatching/business-matching-meetings";
import businessMatchingFilters from "./modules/businessmatching/business-matching-filters";
import survivalRace from "./modules/survival-race";
import moderatorAccesses from "./modules/moderator-accesses";
import exhibitorModerator from "./modules/exhibitor-moderator";
import speakerModerator from "./modules/speaker-moderator";
import utm from "./modules/utm";

import AuthInterceptor from "./plugins/auth-interceptor";
import AccessCodeInterceptor from "./plugins/access-code-interceptor";
import AppIdInterceptor from "./plugins/app-id-interceptor";
import LocaleInterceptor from "./plugins/locale-interceptor";

VuexORM.use(VuexORMAxios, { axios });
VuexORM.use(VuexORMSearch, {});

// eslint-disable-next-line prettier/prettier
export const plugins = [
  VuexORM.install(database),
  LocaleInterceptor.install(axios),
  AuthInterceptor.install(axios),
  AccessCodeInterceptor.install(axios),
  AppIdInterceptor.install(axios),
];

export const state = {
  slug: null,
  event: null,
  appId: null,
  eventId: null,
  timezone: null,
  settings: null,
  eventColors: null,
  currentTime: null,
  askUserToSubmitMetadata: false,
};

export const modules = {
  auth,
  locales,
  facebook,
  google,
  apple,
  accessCode,
  pusher,
  sideMenu,
  adminPanel,
  currentUser,
  userTickets,
  inbox,
  inboxUsers,
  inboxThreads,
  inboxMessages,
  notifications,
  friends,
  components,
  attendees,
  partners,
  exhibitors,
  agendaSessionQuestions,
  speakers,
  quizzes,
  quizAnswers,
  feedWall,
  feedWallChannel,
  feedWallThread,
  eventTickets,
  payment,
  questions,
  businessMatching,
  businessMatchingAvailability,
  businessMatchingSuggestions,
  businessMatchingMeetings,
  businessMatchingFilters,
  quizLeaderboard,
  surveyResults,
  treasureLeaderboard,
  survivalRace,
  moderatorAccesses,
  exhibitorModerator,
  speakerModerator,
  utm,
};

export function createStore() {
  // Enable immutable state mode but only on debug builds
  // Allows only the mutations mutating the store state
  const strict = process.env.NODE_ENV !== "production";

  return new Vuex.Store({
    strict,
    plugins,
    state,
    mutations,
    actions,
    getters,
    modules,
  });
}
