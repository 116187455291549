import { Model } from "@vuex-orm/core";
import AttachmentModel from "./AttachmentModel";
import PartnerContactPersonModel from "@/web/store/models/PartnerContactPersonModel";

export default class PartnerModel extends Model {
  static entity = "partners";

  static fields() {
    return {
      id: this.attr(null),
      partners_category_id: this.number(-1),
      order: this.number(-1),
      name: this.string("").nullable(),
      subname: this.string("").nullable(),
      description: this.string("").nullable(),
      for_logged_contact: this.boolean(false),
      for_logged_sm: this.boolean(false),
      phone: this.string("").nullable(),
      phone_country_code: this.string("").nullable(),
      email: this.string("").nullable(),
      www: this.string("").nullable(),
      facebook_profile: this.string("").nullable(),
      twitter_profile: this.string("").nullable(),
      linked_profile: this.string("").nullable(),
      youtube_profile: this.string("").nullable(),
      instagram_profile: this.string("").nullable(),
      picture_id: this.attr(null),
      picture: this.belongsTo(AttachmentModel, "picture_id"),
      partner_contact_people_ids: this.attr([]),
      partners_contact_people: this.hasManyBy(PartnerContactPersonModel, "partner_contact_people_ids"),
    };
  }
}
