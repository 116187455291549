import {Model} from "@vuex-orm/core";
import EventModel from "./EventModel";

export default class NotificationModel extends Model {
  static entity = "notifications";

  static fields() {
    return {
      id: this.attr(null),
      title: this.string(""),
      message: this.string(""),
      created_at: this.string(""),
      read_at: this.string(null).nullable(),
      notify_type: this.string(""),
      notify_subtype: this.string(""),
      target_type: this.string(null).nullable(),
      target_id: this.number(null).nullable(),
      dl: this.string(""),
      event_id: this.attr(null),
      event: this.belongsTo(EventModel, "event_id"),
    };
  }
}
