function installInterceptors(axios, store) {
  const interceptRequest = createRequestInterceptor(store);

  // eslint-disable-next-line prettier/prettier
  axios.interceptors.request.use(interceptRequest);
}

function createRequestInterceptor(store) {
  const accessCode = () => store.state.accessCode.accessCode;

  return async function(req) {
    const code = accessCode();
    if (code && typeof code === "string") {
      req.headers["AccessCode"] = code;
    }

    return req;
  };
}

export function install(axios) {
  return function(store) {
    installInterceptors(axios, store);
  };
}

export default {
  install
};
