import { Model } from "@vuex-orm/core";

export default class ExhibitorUpdateTranslationModel extends Model {
  static entity = "exhibitor_update_translations";

  static fields() {
    return {
      id: this.attr(null),
      name: this.string("").nullable(),
      scope_description: this.string("").nullable(),
      description: this.string("").nullable(),
      locale: this.string(""),
    };
  }
}
