import { Model } from "@vuex-orm/core";
import ExhibitorModel from "@/web/store/models/ExhibitorModel";

export default class ExhibitorModeratorModel extends Model {
  static entity = "exhibitor_moderators";

  static fields() {
    return {
      id: this.attr(null),
      exhibitor_id: this.attr(null),
      exhibitor: this.belongsTo(ExhibitorModel, "exhibitor_id"),
    };
  }
}
