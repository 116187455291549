import { Model } from "@vuex-orm/core";

export default class ExhibitorUpdateContactPersonTranslationModel extends Model {
  static entity = "exhibitor_update_contact_person_translations";

  static fields() {
    return {
      id: this.attr(null),
      description: this.string("").nullable(),
      locale: this.string(""),
    };
  }
}
