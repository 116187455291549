<template>
  <div class="social-login-footer">
    <p class="has-text-centered mb-1">
      <slot></slot>
    </p>
    <div class="has-text-centered">
      <social-link-list>
<!--        <social-link id="ma-facebook-sign-in-button" type="facebook" @click.native="$emit('facebook')"></social-link>-->
        <social-link id="ma-google-sign-in-button" type="google"></social-link>
        <social-link id="ma-apple-sign-in-button" type="apple" @click.native="$emit('apple')"></social-link>
      </social-link-list>
    </div>
  </div>
</template>

<script>
import SocialLink from "@/web/components/shared/SocialLink";
import SocialLinkList from "@/web/components/shared/SocialLinkList";

export default {
  name: "SocialLoginFooter",

  components: {
    SocialLink,
    SocialLinkList,
  },
};
</script>

<style lang="scss" scoped>
.social-login-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-link {
  font-size: 40px;
}
</style>
