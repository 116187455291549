import appleService from "@/web/services/auth/apple";

export const state = () => ({
  appleToken: null,
});

export const mutations = {
  setAppleToken(state, appleToken) {
    state.appleToken = appleToken;
  },

  clearAppleToken(state) {
    state.appleToken = null;
  },
};

export const actions = {
  async requestAppleToken({ commit }, appId) {
    try {
      const res = await appleService.login(appId);
      const token = res.authorization.id_token;
      commit("setAppleToken", token);
    } catch (error) {
      commit("clearAppleToken");
      throw new Error("User cancelled login or did not fully authorize");
    }
  },

  async logout({ commit, state }) {
    if (state.appleToken) {
      await appleService.logout();
      commit("clearAppleToken");
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
