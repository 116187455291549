<template>
  <div class="has-text-centered">
    <h2 class="title is-spaced is-2 has-text-primary">
      {{ $t("auth.magic_link_sent_title") }}
    </h2>
    <p class="subtitle is-4 has-text-weight-semibold">
      {{ $t("auth.magic_link_sent_subtitle") }}
    </p>
  </div>
</template>

<script>
export default {
  name: "MagicLinkConfirmation"
};
</script>

<style scoped>

</style>
