import {Model} from "@vuex-orm/core";
import UserModel from "@/web/store/models/UserModel";

export default class FriendInvitation extends Model {
  static entity = "friend_invitations";

  static primaryKey = "user_identity_token";

  static fields() {
    return {
      user_identity_token: this.attr(null),
      user: this.belongsTo(UserModel, "user_identity_token"),
      type: this.string(null),
    };
  }
}
