<template>
  <div class="modal-card">
    <header class="modal-card-head has-text-centered">
      <h1 class="modal-card-title">
        {{ title }}
      </h1>
      <div class="close-button" @click="$parent.close()">
        <close-icon class="close-icon"></close-icon>
      </div>
    </header>
    <section class="modal-card-body pt-1 modal-card-foot">
      <div class="size-6 subtitle has-text-centered">
        {{ message }}
      </div>
      <div class="is-flex is-flex-direction-row">
        <button class="button is-fullwidth is-primary" style="margin-top: 1rem" @click="confirm">
          {{ $t("common.confirm") }}
        </button>
        <button class="button is-fullwidth is-danger" style="margin-top: 1rem" @click="cancel">
          {{ $t("common.cancel") }}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import CloseIcon from "@/assets/icon_close.svg";

export default {
  name: "ConfirmationModal",

  props: ["title", "message"],

  components: { CloseIcon },

  methods: {
    cancel() {
      this.$emit("cancel");
      this.$parent.close();
    },

    confirm() {
      this.$emit("confirm");
      this.$parent.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  padding-left: 60px;
  padding-right: 60px;
}

.close-button {
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  position: absolute;
  right: 30px;

  .close-icon {
    fill: #333333;
    width: 100%;
    height: 100%;
  }
}

.subtitle {
  white-space: pre-line;
}
</style>
