import axios from "axios";
import { Constants } from "@/web/constants";

/* eslint-disable prettier/prettier */
const headers = {
  "DeviceId": Constants.API_HEADER_DEVICE_ID,
  "Accept": Constants.API_HEADER_ACCEPT_JSON,
};
/* eslint-enable prettier/prettier */

function validateStatus(status) {
  return status === 200;
}

export function createAxiosInstance() {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_HOST_URL,
    timeout: 30000,
    headers: headers,
    validateStatus,
  });

  return instance;
}
