import { Model } from "@vuex-orm/core";
import AttachmentModel from "./AttachmentModel";
import ExhibitorContactPersonModel from "./ExhibitorContactPersonModel";
import ExhibitorContactUserModel from "./ExhibitorContactUserModel";
import ExhibitorUpdateTranslationModel from "@/web/store/models/ExhibitorUpdateTranslationModel";
import ExhibitorTagModel from "@/web/store/models/ExhibitorTagModel";

export default class ExhibitorModel extends Model {
  static entity = "exhibitors";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.number(-1),
      vip: this.boolean(false),
      order: this.number(-1),
      name: this.string(""),
      scope_description: this.string("").nullable(),
      description: this.string("").nullable(),
      for_logged_contact: this.boolean(false),
      for_logged_sm: this.boolean(false),
      phone: this.string("").nullable(),
      phone_country_code: this.string("").nullable(),
      email: this.string("").nullable(),
      www: this.string("").nullable(),
      facebook_profile: this.string("").nullable(),
      twitter_profile: this.string("").nullable(),
      linked_profile: this.string("").nullable(),
      youtube_profile: this.string("").nullable(),
      instagram_profile: this.string("").nullable(),
      picture_id: this.attr(null),
      picture: this.belongsTo(AttachmentModel, "picture_id"),
      vip_picture_id: this.attr(null),
      vip_picture: this.belongsTo(AttachmentModel, "vip_picture_id"),
      tags_exhibitors_ids: this.attr([]),
      tags_exhibitors: this.hasManyBy(ExhibitorTagModel, "tags_exhibitors_ids"),
      exhibitor_contact_person_ids: this.attr([]),
      exhibitor_contact_people: this.hasManyBy(ExhibitorContactPersonModel, "exhibitor_contact_person_ids"),
      exhibitor_contact_user_ids: this.attr([]),
      exhibitor_contact_users: this.hasManyBy(ExhibitorContactUserModel, "exhibitor_contact_user_ids"),
      exhibitor_attachment_ids: this.attr([]),
      exhibitor_attachments: this.hasManyBy(AttachmentModel, "exhibitor_attachment_ids"),
      video_call_url: this.string("").nullable(),
      promotion_film_url: this.string("").nullable(),
      translation_ids: this.attr([]),
      translations: this.hasManyBy(ExhibitorUpdateTranslationModel, "translation_ids"),
    };
  }
}
