import {Constants} from "@/web/constants";
import NotificationModel from "@/web/store/models/NotificationModel";
import axios from "@/web/services/axios";

const API_LIMIT = 20;

export const state = () => ({
  status: null,
  error: null,
  lastNotificationTimestamp: null,
  allItems: null,
  isMore: true,
  visibleInboxThreadIds: [],
  inboxThreadNotification: null,
  friendNotification: null,
  standardNotification: null,
});

export const types = {
  LOAD_NOTIFICATIONS: "loadNotifications",
  LOAD_NOTIFICATIONS_SUCCESS: "loadNotificationsSuccess",
  LOAD_NOTIFICATIONS_ERROR: "loadNotificationsError",
};

export const mutations = {
  [types.LOAD_NOTIFICATIONS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_NOTIFICATIONS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_NOTIFICATIONS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },

  setMeta(state, {meta, lastNotificationTimestamp}) {
    state.allItems = meta.all_items;
    state.isMore = meta.is_more;
    state.lastNotificationTimestamp = lastNotificationTimestamp;
  },

  resetMeta(state) {
    state.lastNotificationTimestamp = null;
    state.allItems = null;
    state.isMore = true;
  },

  setInboxThreadVisible(state, inboxThreadId) {
    state.visibleInboxThreadIds = [inboxThreadId, ...state.visibleInboxThreadIds];
  },
  setInboxThreadInvisible(state, inboxThreadId) {
    state.visibleInboxThreadIds = state.visibleInboxThreadIds.filter(threadId => threadId !== inboxThreadId);
  },

  setInboxThreadNotification(state, inboxThread) {
    if (!state.visibleInboxThreadIds.includes(inboxThread.id)) {
      state.inboxThreadNotification = inboxThread;
    }
  },
  clearInboxThreadNotification(state) {
    state.inboxThreadNotification = null;
  },

  setFriendNotification(state, friendNotification) {
    state.friendNotification = friendNotification;
  },
  clearFriendNotification(state) {
    state.friendNotification = null;
  },

  setStandardNotification(state, standardNotification) {
    state.standardNotification = standardNotification;
  },
  clearStandardNotification(state) {
    state.standardNotification = null;
  },
};

export const actions = {
  async loadNotificationsPage({commit, getters, state}) {
    if (!getters.isLoading) {
      commit(types.LOAD_NOTIFICATIONS);
      const params = {
        limit: API_LIMIT,
        timestamp: state.lastNotificationTimestamp,
      };

      return await NotificationModel.api()
        .get(`notifications`, {
          params: params,
          dataTransformer: ({data, headers}) => {
            const lastNotificationTimestamp = new Date(data.notifications.slice(-1).pop().created_at).getTime();
            commit("setMeta", {meta: data.meta, lastNotificationTimestamp});
            return data.notifications;
          },
        })
        .then(result => {
          commit(types.LOAD_NOTIFICATIONS_SUCCESS);
        })
        .catch(err => {
          commit(types.LOAD_NOTIFICATIONS_ERROR, err);
        });
    }
  },
  async refreshNotifications({commit, getters, state}, {showNotification, notificationId}) {
    const params = {
      limit: API_LIMIT,
    };

    return await NotificationModel.api()
      .get(`notifications`, {
        params: params,
        dataTransformer: ({data, headers}) => {
          return data.notifications;
        },
      })
      .then(result => {
        const notification = result.response.data.notifications.filter(notification => notification.target_id === notificationId);
        if (showNotification && notification.length) {
          commit("setStandardNotification", notification[0]);
        }
      })
      .catch(err => {
      });
  },

  async markNotificationAsRead({commit}, notificationId) {
    const body = {
      ids: [notificationId],
    };
    return await axios.patch("notifications", body).then(result => {
      NotificationModel.update({
        where: notificationId,
        data: {
          read_at: new Date().toISOString(),
        },
      });
    });
  },

  async clearNotifications({commit}) {
    commit("resetMeta");
    return await NotificationModel.deleteAll();
  }
};

export const getters = {
  getNotifications: state => {
    // eslint-disable-next-line
    return NotificationModel.query()
      .withAllRecursive()
      .orderBy(notification => new Date(notification.created_at).getTime(), "desc")
      .all();
  },

  isLoading(state) {
    return state.status === Constants.STATUS_LOADING;
  },

  hasUnreadNotifications(state) {
    const latestNotification = NotificationModel.query()
      .withAllRecursive()
      .orderBy(notification => new Date(notification.created_at).getTime(), "desc")
      .first();
    return latestNotification && !latestNotification.read_at;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
