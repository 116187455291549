import { Model } from "@vuex-orm/core";
import { STATUS_NO_DATA } from "@/web/constants";
import PartnerModel from "./PartnerModel";

export default class PartnerCategoryModel extends Model {
  static entity = "partner_categories";

  static fields() {
    return {
      id: this.attr(null),
      event_component_id: this.number(-1),
      order: this.number(-1),
      level: this.number(-1),
      name: this.string(null).nullable(),
      partner_ids: this.attr([]),
      partners: this.hasManyBy(PartnerModel, "partner_ids")
    };
  }
}
