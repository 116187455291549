import { Constants } from "@/web/constants";
import ComponentModel from "@/web/store/models/ComponentModel";
import axios from "@/web/services/axios";

export const state = {
  status: null,
  error: null,
  slug: null,
};

export const types = {
  LOAD_COMPONENTS: "loadComponents",
  LOAD_COMPONENTS_SUCCESS: "loadComponentsSuccess",
  LOAD_COMPONENTS_ERROR: "loadComponentsError",
  COMPONENTS_NOT_INITIALIZED: "componentsNotInitialized",
};

export const mutations = {
  [types.COMPONENTS_NOT_INITIALIZED](state) {
    state.status = null;
  },
  [types.LOAD_COMPONENTS](state) {
    state.status = Constants.STATUS_LOADING;
  },
  [types.LOAD_COMPONENTS_SUCCESS](state) {
    state.status = Constants.STATUS_LOADED;
  },
  [types.LOAD_COMPONENTS_ERROR](state, error) {
    state.status = Constants.STATUS_ERROR;
    state.error = error;
  },
};
export const actions = {
  fetchComponents({ commit, rootGetters }, { eventId }) {
    const localComponentIds = ComponentModel.query()
      .all()
      .map(component => component.id);
    return ComponentModel.api()
      .get(`events/${eventId}/components`, { persistBy: "create" })
      .then(result => {
        const remoteComponentIds = result.response.data.map(component => component.id);
        let idsToDelete = localComponentIds.filter(componentId => !remoteComponentIds.includes(componentId));
        if (idsToDelete.length) {
          ComponentModel.delete(component => idsToDelete.includes(component.id));
        }
        commit(types.LOAD_COMPONENTS_SUCCESS);
      })
      .catch(err => {
        commit(types.LOAD_COMPONENTS_ERROR, err);
        throw err;
      });
  },
  deleteComponents({ commit }) {
    commit(types.COMPONENTS_NOT_INITIALIZED);
    return ComponentModel.deleteAll();
  },

  async getCommonComponentsList({ rootState }, userUuid) {
    const eventId = rootState.eventId;
    const params = {
      user_identity_token: userUuid,
    };
    return axios.get(`events/${eventId}/components/users_similar_components`, { params }).then(result => {
      return result.data;
    });
  },
};
export const getters = {
  isLoaded(state) {
    return state.status === Constants.STATUS_LOADED;
  },
  getComponentById: state => componentId => {
    return ComponentModel.query().where("id", componentId).get()[0];
  },
  allComponents() {
    return ComponentModel.query().get();
  },
  agendaComponents() {
    return ComponentModel.query().where("component_name", Constants.AGENDA_COMPONENT_TYPE).get();
  },
  businessMatchingComponents() {
    return ComponentModel.query()
      .where(component => {
        return component.component_name === Constants.BUSINESS_MATCHING_COMPONENT_TYPE &&
          component.additional_info.business_matching_type !== Constants.BUSINESS_MATCHING_TYPE_STRICT;
      })
      .get();
  },
  eventInfoComponent() {
    return ComponentModel.query().where("component_name", Constants.EVENT_INFO_COMPONENT).first();
  },
  quizComponents(state, getters) {
    return ComponentModel.query().where("component_name", Constants.QUIZZES_COMPONENT_TYPE).get();
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
