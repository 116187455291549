import Constants from "@/web/constants";
import createStorage from "@/web/services/storage";
import LpConfigConstants from "@/shared/constants/lp-config-constants";

const sideMenuStorage = createStorage("side-menu");

export const state = () => ({
  bubbleToOpen: null,
  feedWallUpdatePayload: null,
  sideMenuPanel: false,
  sideMenuPanelSneakPeek: false,
});

export const types = {
  OPEN_BUBBLE: "openBubble",
};

export const mutations = {
  [types.OPEN_BUBBLE](state, bubble) {
    state.bubbleToOpen = bubble;
  },

  setSideMenuPanel(state, sideMenuPanel) {
    state.sideMenuPanel = sideMenuPanel;
    if (!sideMenuPanel) {
      state.sideMenuPanelSneakPeek = true;
    }
  },

  setSideMenuPanelSneakPeakMode(state, isEnabled) {
    state.sideMenuPanelSneakPeek = isEnabled;
  },

  toggleSideMenuPanelSneakPeakMode(state) {
    state.sideMenuPanelSneakPeek = !state.sideMenuPanelSneakPeek;
  },

  resetSideMenuPanel(state) {
    state.sideMenuPanel = null;
    state.sideMenuPanelSneakPeek = false;
  },

  bubbleOpened(state) {
    state.bubbleToOpen = null;
  },

  setFeedWallUpdatePayload(state, payload) {
    state.feedWallUpdatePayload = payload;
  },
};

export const actions = {
  openInboxBubbleWithThread({ commit, getters }, inboxThreadId) {
    let bubble = {
      type: Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES,
      inboxThreadId: inboxThreadId,
      isFresh: true,
    };
    if (getters.isSideMenuBubbleType) {
      commit(types.OPEN_BUBBLE, bubble);
    } else {
      commit("setSideMenuPanel", bubble);
    }
  },

  openInboxBubbleWithUser({ commit, getters }, userUuid) {
    let bubble = {
      type: Constants.SIDE_MENU_BUBBLE_TYPES.INBOX_MESSAGES,
      inboxUserUuid: userUuid,
      isFresh: true,
    };
    if (getters.isSideMenuBubbleType) {
      commit(types.OPEN_BUBBLE, bubble);
    } else {
      commit("setSideMenuPanel", bubble);
    }
  },

  openFeedWallBubble({ commit, getters }, { feedWallChannelId, feedWallThreadId }) {
    let bubble = {
      type: Constants.SIDE_MENU_BUBBLE_TYPES.FEED_WALL_BUBBLE,
      feedWallChannelId: feedWallChannelId,
      feedWallThreadId: feedWallThreadId,
      isFresh: true,
    };
    if (getters.isSideMenuBubbleType) {
      commit(types.OPEN_BUBBLE, bubble);
    } else {
      commit("setSideMenuPanel", bubble);
    }
  },

  openQuizBubble({ commit, getters }, quizId) {
    let bubble = {
      type: Constants.SIDE_MENU_BUBBLE_TYPES.QUIZZES_BUBBLE,
      quizId: quizId,
      isFresh: true,
    };
    if (getters.isSideMenuBubbleType) {
      commit(types.OPEN_BUBBLE, bubble);
    } else {
      commit("setSideMenuPanel", bubble);
    }
  },

  openAgendaSessionQuestionsBubble({ commit, getters }, { agendaComponentId, agendaSessionId }) {
    let bubble = {
      type: Constants.SIDE_MENU_BUBBLE_TYPES.AGENDA_SESSION_QUESTIONS,
      componentId: agendaComponentId,
      agendaSessionId: agendaSessionId,
      isFresh: true,
    };
    if (getters.isSideMenuBubbleType) {
      commit(types.OPEN_BUBBLE, bubble);
    } else {
      commit("setSideMenuPanel", bubble);
    }
  },

  handleFeedWallUpdateRequest({ commit }, payload) {
    commit("setFeedWallUpdatePayload", payload);
  },
};

export const getters = {
  isSideMenuBubbleType(state, getters, rootState, rootGetters) {
    return rootGetters["sideMenuType"] === LpConfigConstants.SIDE_MENU_TYPES.BUBBLES;
  },

  sideMenuBubblesTooltips(state, getters, rootState, rootGetters) {
    const sideMenuSettings = rootGetters["lpSettings"][LpConfigConstants.ROOT_FIELDS.SIDE_MENU];
    if (sideMenuSettings && sideMenuSettings[LpConfigConstants.SIDE_MENU_FIELDS.TOOLTIPS]) {
      return sideMenuSettings[LpConfigConstants.SIDE_MENU_FIELDS.TOOLTIPS];
    } else {
      return null;
    }
  },

  sideMenuBubblesTitles(state, getters, rootState, rootGetters) {
    const sideMenuSettings = rootGetters["lpSettings"][LpConfigConstants.ROOT_FIELDS.SIDE_MENU];
    if (sideMenuSettings && LpConfigConstants.SIDE_MENU_FIELDS.TITLES in sideMenuSettings) {
      return sideMenuSettings[LpConfigConstants.SIDE_MENU_FIELDS.TITLES];
    } else {
      return null;
    }
  },

  sideMenuBubblesIcons(state, getters, rootState, rootGetters) {
    const sideMenuSettings = rootGetters["lpSettings"][LpConfigConstants.ROOT_FIELDS.SIDE_MENU];
    if (sideMenuSettings && LpConfigConstants.SIDE_MENU_FIELDS.ICONS in sideMenuSettings) {
      return sideMenuSettings[LpConfigConstants.SIDE_MENU_FIELDS.ICONS];
    } else {
      return null;
    }
  },

  isSideMenuPanelOpen(state) {
    return !!state.sideMenuPanel;
  },

  isSideMenuPanelSneakPeakMode(state) {
    return !!state.sideMenuPanelSneakPeek;
  },
};

function getBubbleTitle(lpSettings, bubbleType) {
  const sideMenuSettings = lpSettings[LpConfigConstants.ROOT_FIELDS.SIDE_MENU];

  if (sideMenuSettings && LpConfigConstants.SIDE_MENU_FIELDS.TITLES in sideMenuSettings) {
    if (bubbleType in sideMenuSettings[LpConfigConstants.SIDE_MENU_FIELDS.TITLES]) {
      return sideMenuSettings[LpConfigConstants.SIDE_MENU_FIELDS.TITLES][bubbleType];
    }
  }
  return null;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
