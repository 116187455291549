import { Model } from "@vuex-orm/core";

export default class AgendaSessionPathModel extends Model {
  static entity = "agenda_session_paths";

  static fields() {
    return {
      id: this.attr(null),
      value: this.string(""),
      hex: this.string(""),
      event_id: this.attr(null)
    };
  }
}
